import React from 'react';
import { Link } from 'react-router-dom';
import { imageRootlink, videoRootlink } from '../functions/link';

export default function PinnedPostCard(props) {
    return (
        <div className="PinnedCard" style={{ minWidth: 270 }}>
            <div className="Pinnedcard-header">
                <div className="Pinnedcard-header-left">
                    <h6>{ props.post.location.location} - {new Date(props.post.created_at).toLocaleDateString()}</h6>
                </div>
                <div className="Pinnedcard-header-right">
                    <button type="button" className="btn btn-link">
                        <i className="far fa-ellipsis-v"></i>
                    </button>
                </div>
            </div>
            <div className="Pinnedcard-header-caption">
                {
                    props.post.contents === null
                        ?
                        <p>.</p>
                        :
                        <p>{props.post.contents[0].description === "" ? "." : props.post.contents[0].description}</p>
                }
                {/* <p>{props.post.contents.length == 0 ? "." : }</p> */}
            </div>
            <div className="Pinnedcard-image-box">
                <div className="Pinnedcard-image">
                    {
                        props.post.media === null || props.post.media[0] == undefined
                            ?
                            null
                            :
                            props.post.media[0].path.includes(".mp4")
                                ?
                                <video src={imageRootlink + "/post/" + props.post.media[0].path} controls className="img-fluid"></video>
                                :
                                <img src={videoRootlink + "/post/" + props.post.media[0].path} className="img-fluid" style={{ maxHeight: props.isFullHeight ? "auto" : "200px" }} />
                    }
                    {/* <img src="assets/images/PostPlaceholder.png" className="img-fluid" /> */}
                </div>
                {/* <div className="Pinnedcard-view-btn">
                    <i className="fal fa-eye"></i> 1200 Views
                </div> */}
            </div>
            <div className="Pinnedcard-footer">
                <Link to={"/post/" + props.post.slug}> <i className="fal fa-eye"></i> View Original Post</Link>
            </div>
        </div>
    )
}