const CommunityCard = require('./community_card.png');
const Email = require('./email.png');
const Website = require('./website.png');
const Phone = require('./phone.png');
const Logo = require('./main_logo.png');
const Location = require('./address_icon.png');
const Design1 = require('./Vector.png');
const Design2 = require('./Frame 20.png');
const Signup = require('./signup_icon.svg');
const Share = require('./sharing.svg');
const Fire = require('./fire.svg');


 const IMAGE_PATH={
    CommunityCard,
    Email,
    Website,
    Phone,
    Logo,
    Location,
    Design1,
    Design2,
    Signup,
    Share,
    Fire

}

module.exports = IMAGE_PATH;