export const gradient = [
    {
        id: 1,
        gradient:
            ["#B08FFD", "#8386FE", "#766F00", "#FA15A6"],
            style:{
                div:{},
                name:{},
                profession:{},
                message:{},
                
            }
    },

    {
        id: 2,
        gradient:
            ["#B08FFD", "#f1ff", "#766F00"]
    },
    {
        id: 3,
        gradient:
            ["#B08FFD", "#8386FE", "#7C82FF", "#7D79FF", "#8161FF", "#883AFF", "#8F15FF"]
    },
    {
        id: 4,
        gradient:
            ["#6CC3F4", "#7FAAE9", "#B26DCD", "#FA15A6"]
    },
    {
        id: 5,
        gradient: [
           "#F17650",
            "#CE8540",
           
        ]
    },{
        id: 6,
        gradient: [
           "#E16488",
            "#AA14F2",
           
        ]
       
    }

]