import React from 'react';
import { MdShare } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RWebShare } from 'react-web-share';
import { Colors } from '../constants/colors';
import { getLoggedUser } from '../functions/helper';
import { contestShareLink } from '../functions/link';
import { setShowAlertPopup } from '../redux/dataSlice';

export default function ContestCard(props) {
    let navigate = useNavigate();
    const dispatch = useDispatch()

    const handleGotoContest = () => {
        if (getLoggedUser()) {
            navigate('/contest', { state: { contest_id: props.contest.id } })
        } else {
            dispatch(
                setShowAlertPopup(
                    {
                        show: true,
                        title: "Alert",
                        message: "Login to see contest.",
                        showLoginBtn: true
                    }
                )
            );
        }
    }

    // const handleShareSadhana = () => {


    // }

    // console.log(props)

    const transparentBtn = {
        backdropFilter: 'blur(2px)',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "0.625rem",
        borderRadius: "20px",
    }


    return (
        <div className="px-2 bd-highlight " style={{ maxWidth: "600px" }}>
            <div className="Big-card big-card-bg-two">
                <div className="Big-card-body" style={{ position: "relative", overflow: "hidden", zIndex: 1 }}>

                    <div onClick={handleGotoContest} style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, borderRadius: 16, overflow: "hidden", zIndex: -1, height: "100%" }}>
                        <img alt='localbol'
                            src={props.contest.thumbnail_image}
                            className="img-fluid"
                            style={{ zIndex: 1, height: "100%", width: "100%" }}
                            loading="lazy"
                        />
                    </div>
                    <RWebShare
                        data={{
                            //text: props?.post?.contents[0]?.text,
                            url: `${contestShareLink}/${props?.contest.id}`,
                            title: "Share this Sadhana",
                        }}
                    >
                        <div
                            className="px-2 mt-4 btn text-center "
                            
                                style={{
                                    color: Colors.primary,
                                    position: 'absolute', right: 10, top: -10,
                                    ...transparentBtn,
                                    fontSize:'0.8rem'
                       
                            }}
                        >
                            <div className="d-flex justify-content-center align-items-center">
                                <MdShare />
                                <p className="ml-1 m-0">Share</p>
                            </div>
                        </div>
                    </RWebShare>
                    <div style={{ position: 'absolute', right: 10, bottom: 10, display: 'flex', flexDirection: 'column' }}>
                        {
                            props?.contest?.hashtag
                                ?
                                props.contest?.hashtag?.toString()?.split(",")?.map((tag, index) => {
                                    return (
                                        <button
                                            key={index}
                                            type="button"
                                            className="btn  text-white" style={{
                                                marginRight: "auto",
                                                borderColor: Colors.white,
                                                ...transparentBtn
                                            }}>
                                            #{tag}
                                        </button>

                                    )
                                })
                                :
                                null
                        }


                    </div>

                </div>
            </div >



        </div >
        // <div style={{ maxWidth: "600px",backgroundColor:Colors.lightGrey,height:'200px' }}>
        //     <div onClick={handleGotoContest} style={{ borderRadius:16, overflow: "hidden", zIndex: -1, height: "100%" }}>
        //         <img alt='localbol'
        //             src={props.contest.thumbnail_image}
        //             className="img-fluid"
        //             style={{ zIndex: 1, height: "100%", width: "100%" }}
        //             loading="lazy"
        //         />
        //            <div style={{zIndex:99}}>
        //         {
        //             props?.contest?.hashtag
        //                 ?
        //                 props.contest?.hashtag?.toString()?.split(",")?.map((tag, index) => {
        //                     return (
        //                         <button
        //                             key={index}
        //                             type="button"
        //                             className="btn btn-light-small" style={{ marginRight: "auto" }}>
        //                             #{tag}
        //                         </button>

        //                     )
        //                 })
        //                 :
        //                 null
        //         }
        //            <RWebShare
        //         data={{
        //             //text: props?.post?.contents[0]?.text,
        //             url: `${contestShareLink}/${props?.contest.id}`,
        //             title: "Share this Sadhana",
        //         }}
        //     //  onClick={handleShareSadhana}
        //     >
        //         <div

        //             style={{ color: Colors.primary, float: "right" }}
        //         >
        //             <div className="d-flex justify-content-center align-items-center">
        //                 <MdShare />
        //                 <p className="ml-1 m-0">Share</p>
        //             </div>
        //         </div>
        //     </RWebShare>


        //         {/* <div style={{ color: "orangered", display: "inline", marginRight: "auto" }} className="btn btn-light-small">

        //                     {
        //                         props.contest.details && props.contest.details[0]
        //                             ?
        //                             props.contest.details[0].title
        //                             :
        //                             null
        //                     }
        //                 </div> */}

        //     </div>
        //     </div>
        //     {/* <RWebShare
        //         data={{
        //             //text: props?.post?.contents[0]?.text,
        //             url: `${contestShareLink}/${props?.contest.id}`,
        //             title: "Share this Sadhana",
        //         }}
        //       //  onClick={handleShareSadhana}
        //     >
        //         <div
        //             // onClick={handleShareSadhana}
        //             className=" p-2 mt-4 btn text-center "
        //             style={{ backgroundColor: Colors.white, color: Colors.primary, marginLeft: "auto" ,display: "flex", flexDirection: "column", position: 'relative', bottom: 20, right: 0,zIndex:10}}
        //         >
        //             <div className="d-flex justify-content-center align-items-center">
        //                 <MdShare />
        //                 <p className="ml-1 m-0">Share</p>
        //             </div>
        //         </div>
        //     </RWebShare> */}


        // </div>


    )
}

{/* <div className="px-2 bd-highlight " style={{ maxWidth: "600px" }}>
<div onClick={handleGotoContest} className="Big-card big-card-bg-two">
    <div className="Big-card-body" style={{ position: "relative", overflow: "hidden", zIndex: 1 }}>

        <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, borderRadius: 16, overflow: "hidden", zIndex: -1, height: "100%" }}>
            <img alt='localbol'
                src={props.contest.thumbnail_image}
                className="img-fluid"
                style={{ zIndex: 1, height: "100%", width: "100%" }}
                loading="lazy"
            />
        </div>

        <div  style={{position:'absolute',right:10,bottom:10,display:'flex',flexDirection:'column'}}>
            {
                props?.contest?.hashtag
                    ?
                    props.contest?.hashtag?.toString()?.split(",")?.map((tag, index) => {
                        return (
                            <button
                                key={index}
                                type="button"
                                className="btn btn-light-small" style={{ marginRight: "auto" }}>
                                #{tag}
                            </button>

                        )
                    })
                    :
                    null
            }


        </div>


    </div>
</div>

<RWebShare
    data={{
        //text: props?.post?.contents[0]?.text,
        url: `${contestShareLink}/${props?.contest.id}`,
        title: "Share this Sadhana",
    }}
  //  onClick={handleShareSadhana}
>
    <div
        // onClick={handleShareSadhana}
        className=" p-2 mt-4 btn text-center "
        style={{ backgroundColor: Colors.white, color: Colors.primary, marginLeft: "auto" ,display: "flex", flexDirection: "column", position: 'relative', bottom: 20, right: 0,zIndex:10}}
    >
        <div className="d-flex justify-content-center align-items-center">
            <MdShare />
            <p className="ml-1 m-0">Share</p>
        </div>
    </div>
</RWebShare> 
    <RWebShare
                data={{
                    //text: props?.post?.contents[0]?.text,
                    url: `${contestShareLink}/${props?.contest.id}`,
                    title: "Share this Sadhana",
                }}
            //  onClick={handleShareSadhana}
            >
                <div
                  
                    style={{  color: Colors.primary, float:"right"}}
                >
                    <div className="d-flex justify-content-center align-items-center">
                        <MdShare />
                        <p className="ml-1 m-0">Share</p>
                    </div>
                </div>
            </RWebShare>
</div> */}