import React from 'react'
import { useDispatch } from 'react-redux';
import { customRequest } from '../../functions/request';
import { setShowAlertPopup } from '../../redux/dataSlice';

const RespectComponent = ({ user_id }) => {
    let [respected, setRespected] = React.useState(false);

    const dispatch = useDispatch()
    const handleRespect = () => {
        if (!localStorage.getItem('token')) {
            dispatch(
                setShowAlertPopup(
                    {
                        show: true,
                        title: "Alert",
                        message: "Login to respect.",
                        showLoginBtn: true
                    }
                )
            );
            return;
        }

        customRequest('user/respect-user', { user_id }).then((res) => {
            console.log(res)
            if (res.msg == "Respected Successfully.") {
                setRespected(true);
            } else {
                dispatch(
                    setShowAlertPopup(
                        {
                            show: true,
                            title: "Alert",
                            message: "Something went wrong",
                        }
                    )
                );
            }
        })
    }
    return (
        <div className="bd-highlight">
            <button type="button" className="btn btn-orange" onClick={handleRespect}>
                <i className="far fa-plus"></i> {respected ? "Respected" : "Respect"}
            </button>
        </div>
    )
}

export default RespectComponent