import React from 'react';

export default function InfoScreen(){
    <React.Fragment>
        <div className="main_warp">
         <div
            className="aboutheader"
            style={{minHeight: '250px',
            backgroundImage: 'url(assets/images/PostPlaceholder.png)',
            backgroundSize: 'cover',
            }}
            >
            <div className="logohead">
               <a href="/"><img src="assets/images/logosm.png" alt=""/></a>
            </div>
            <a className="loactionLefticons" href="/index.html">
            <i className="far fa-arrow-left fa-lg" style={{color: "#121416"}}></i>
            </a>
            <div className="loactionbottom">
               <h4>Mumbai, India</h4>
               <h5>4,500,465 POSTS</h5>
            </div>
         </div>
         <div className="container px-0">
            <div className="productdetail">
               <div className="productdetailsmallnav">
                  <a className="productdetailsmallnavactive" href="/#Info">Info</a>
                  <a href="/Trending.html">Trending Posts</a>
                  <a href="/Allposts.html">All Posts</a>
                  <a href="/Faq.html">FAQs</a>
                  <a href="/Authorities.html">Local Authorities</a>
               </div>
               <div className="productdetailsmallview" id="#info">
                  <div className="card">
                     <div className="card-header">
                        <h4>
                           <i className="far fa-info-circle mr-3"></i> About this place
                        </h4>
                     </div>
                     <div className="card-body">
                        <p className="card-text">
                           Phasellus accumsan imperdiet tempor. Cras tincidunt, arcu
                           nec eleifend porttitor, orci est vehicula velit, scelerisque
                           fringilla ligula mi in urna. Nulla ut odio eu mi congue
                           molestie. Maecenas tortor neque, condimentum ut odio in,
                           mollis efficitur enim. In dui ante, faucibus quis vulputate
                           nec, aliquet et elit.
                        </p>
                        <p className="card-text">
                           Phasellus accumsan imperdiet tempor. Cras tincidunt, arcu
                           nec eleifend porttitor, orci est vehicula velit, scelerisque
                           fringilla ligula mi in urna.
                        </p>
                     </div>
                  </div>
                  <div className="card">
                     <div className="card-header">
                        <h4>
                           <i className="far fa-map-marker-alt mr-3"></i> Popular Locations
                        </h4>
                     </div>
                     <div className="card-body">
                        <div className="d-flex flex-column bd-highlight">
                           <div className="bd-highlight">
                              <div
                                 className="d-flex flex-row bd-highlight flex-wrap justify-content-between mb-3"
                                 >
                                 <div className="p-1 bd-highlight">
                                    <div className="right-box-view">
                                       <div className="right-box-img">
                                          <img
                                             src="assets/images/Profileimages2.jpg"
                                             className="img-fluid"
                                             />
                                       </div>
                                       <h6>Ariport</h6>
                                    </div>
                                 </div>
                                 <div className="p-1 bd-highlight">
                                    <div className="right-box-view">
                                       <div className="right-box-img">
                                          <img
                                             src="assets/images/Profileimages2.jpg"
                                             className="img-fluid"
                                             />
                                       </div>
                                       <h6>Ariport</h6>
                                    </div>
                                 </div>
                                 <div className="p-1 bd-highlight">
                                    <div className="right-box-view">
                                       <div className="right-box-img">
                                          <img
                                             src="assets/images/Profileimages2.jpg"
                                             className="img-fluid"
                                             />
                                       </div>
                                       <h6>Ariport</h6>
                                    </div>
                                 </div>
                                 <div className="p-1 bd-highlight">
                                    <div className="right-box-view">
                                       <div className="right-box-img">
                                          <img
                                             src="assets/images/Profileimages2.jpg"
                                             className="img-fluid"
                                             />
                                       </div>
                                       <h6>Ariport</h6>
                                    </div>
                                 </div>
                                 <div className="p-1 bd-highlight">
                                    <div className="right-box-view">
                                       <div className="right-box-img">
                                          <img
                                             src="assets/images/Profileimages2.jpg"
                                             className="img-fluid"
                                             />
                                       </div>
                                       <h6>Ariport</h6>
                                    </div>
                                 </div>
                                 <div className="p-1 bd-highlight">
                                    <div className="right-box-view">
                                       <div className="right-box-img">
                                          <img
                                             src="assets/images/Profileimages2.jpg"
                                             className="img-fluid"
                                             />
                                       </div>
                                       <h6>Ariport</h6>
                                    </div>
                                 </div>
                                 <div className="p-1 bd-highlight">
                                    <div className="right-box-view">
                                       <div className="right-box-img">
                                          <img
                                             src="assets/images/Profileimages2.jpg"
                                             className="img-fluid"
                                             />
                                       </div>
                                       <h6>Ariport</h6>
                                    </div>
                                 </div>
                                 <div className="p-1 bd-highlight">
                                    <div className="right-box-view">
                                       <div className="right-box-img">
                                          <img
                                             src="assets/images/Profileimages2.jpg"
                                             className="img-fluid"
                                             />
                                       </div>
                                       <h6>Ariport</h6>
                                    </div>
                                 </div>
                                 <div className="p-1 bd-highlight">
                                    <div className="right-box-view">
                                       <div className="right-box-img">
                                          <img
                                             src="assets/images/Profileimages2.jpg"
                                             className="img-fluid"
                                             />
                                       </div>
                                       <h6>Ariport</h6>
                                    </div>
                                 </div>
                                 <div className="p-1 bd-highlight">
                                    <div className="right-box-view">
                                       <div className="right-box-img">
                                          <img
                                             src="assets/images/Profileimages2.jpg"
                                             className="img-fluid"
                                             />
                                       </div>
                                       <h6>Ariport</h6>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="p-1 bd-highlight text-center">
                              <button type="button" className="btn btn-light">
                              Show More
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="card">
                     <div className="card-header">
                        <h4>
                           <i className="fal fa-smile mr-3"></i> Popular Mumbai Moods
                        </h4>
                     </div>
                     <div className="card-body">
                        <button type="button" className="btn btn-dark-small">
                        mumbaikar
                        </button>
                        <button type="button" className="btn btn-dark-small">
                        mumbailife
                        </button>
                        <button type="button" className="btn btn-dark-small">
                        Mumbaitrains
                        </button>
                        <button type="button" className="btn btn-dark-small">
                        mumbainightlife
                        </button>
                        <button type="button" className="btn btn-dark-small">
                        kuchbhimatbol
                        </button>
                        <button type="button" className="btn btn-dark-small">
                        bantai
                        </button>
                        <button type="button" className="btn btn-dark-small">
                        timepass
                        </button>
                        <button type="button" className="btn btn-dark-small">
                        mumbaivibes
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div
         className="modal windowmodal fade"
         id="exampleModal"
         data-bs-backdrop="static"
         data-bs-keyboard="false"
         tabIndex="-1"
         aria-labelledby="staticBackdropLabel"
         aria-hidden="true"
         >
         <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
               <div className="modal-header">
                  <div
                     className="modal-close ml-auto"
                     data-bs-dismiss="modal"
                     aria-label="Close"
                     >
                     <img src="assets/images/Arrow-Left.svg" />
                  </div>
               </div>
               <div className="modal-body py-0 px-2">
                  <div
                     id="carouselExampleIndicators"
                     className="carousel slide"
                     data-bs-ride="carousel"
                     >
                     <div className="carousel-indicators">
                        <button
                           type="button"
                           data-bs-target="#carouselExampleIndicators"
                           data-bs-slide-to="0"
                           className="active"
                           aria-current="true"
                           aria-label="Slide 1"
                           ></button>
                        <button
                           type="button"
                           data-bs-target="#carouselExampleIndicators"
                           data-bs-slide-to="1"
                           aria-label="Slide 2"
                           ></button>
                     </div>
                     <div className="carousel-inner">
                        <div className="carousel-item active">
                           <div className="modal-logo">
                              <img src="assets/images/Localbol.svg" width="70" />
                           </div>
                           <div className="carousel-caption d-md-block mt-0 pt-0">
                              <h3 className="mb-1" style={{color:"#121416"}}>Welcome to Local Bol</h3>
                              <p className="m-0 p-0">
                                 A Community of user with verified facts. Amet minim
                                 mollit non deserunt ullamco est sit aliqua dolor do Amet
                                 simt. Exercitation veniam consequat sunt nostrud ament.A
                                 community of user with verified facts. Amet mini mollis
                                 non deserunt ullamco est sit aliqua dolor do amet
                                 sint.Exercitation veniam consequat sunt nostrud amey.
                              </p>
                           </div>
                        </div>
                        <div className="carousel-item">
                           <div className="modal-logo">
                              <img src="assets/images/Modalimage.svg" width="80" />
                           </div>
                           <div className="carousel-caption d-md-block mt-0 pt-0">
                              <div className="d-flex flex-column bd-highlight">
                                 <div className="py-1 bd-highlight">
                                    <h3 className="mb-1" style={{color:"#121416"}}>Select Language..</h3>
                                    <p className="m-0 p-0">For best experience and  related content</p>
                                 </div>
                                 <div className="py-1 bd-highlight">
                                    <button type="button" className="btn btn-light-modal-active">English<i className="fal fa-times ms-1"></i></button>  
                                    <button type="button" className="btn btn-light-modal">हिंदी<i className="fal fa-times ms-1 d-none"></i></button> 
                                    <button type="button" className="btn btn-light-modal">मराठी<i className="fal fa-times ms-1 d-none"></i></button> 
                                    <button type="button" className="btn btn-light-modal">ગુજરાતી<i className="fal fa-times ms-1 d-none"></i></button>
                                    <button type="button" className="btn btn-light-modal">কাজ<i className="fal fa-times ms-1 d-none"></i></button>
                                    <button type="button" className="btn btn-light-modal">മലയാളം<i className="fal fa-times ms-1 d-none"></i></button>
                                    <button type="button" className="btn btn-light-modal">Mandarin<i className="fal fa-times ms-1 d-none"></i></button> 
                                    <button type="button" className="btn btn-light-modal">French<i className="fal fa-times ms-1 d-none"></i></button>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="modal-btn">
                        <button type="button" data-bs-toggle="modal"
                           data-bs-target="#LocationModal" className="btn btn-orange-big">
                        coutinue
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div
         className="modal windowmodal fade"
         id="LocationModal"
         data-bs-backdrop="static"
         data-bs-keyboard="false"
         tabIndex="-1"
         aria-labelledby="staticBackdropLabel"
         aria-hidden="true"
         >
         <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
               <div className="modal-header">
                  <div
                     className="modal-close ml-auto"
                     data-bs-dismiss="modal"
                     aria-label="Close"
                     >
                     <img src="assets/images/Arrow-Left.svg" />
                  </div>
               </div>
               <div className="modal-body py-0 px-2">
                  <div
                     id="carouselExampleIndicators"
                     className="carousel slide"
                     data-bs-ride="carousel"
                     >
                     <div className="carousel-indicators">
                        <button
                           type="button"
                           data-bs-target="#carouselExampleIndicators"
                           data-bs-slide-to="0"
                           className="active"
                           aria-current="true"
                           aria-label="Slide 1"
                           ></button>
                        <button
                           type="button"
                           data-bs-target="#carouselExampleIndicators"
                           data-bs-slide-to="1"
                           aria-label="Slide 2"
                           ></button>
                     </div>
                     <div className="carousel-inner" style={{height: "280px"}}>
                        <div className="carousel-item active">
                           <div className="modal-logo-medium">
                              <img src="assets/images/ModalImagebig.svg" width="150" />
                           </div>
                           <div className="carousel-caption d-md-block mt-0 pt-0">
                              <h3 className="mb-1" style={{color:"#121416"}}>Show world around me..</h3>
                              <p className="m-0 p-0">
                                 Enable Location Services to explore what’s happening around you
                              </p>
                           </div>
                        </div>
                        <div className="carousel-item">
                           <div className="modal-logo">
                              <img src="assets/images/Modalimage.svg" width="80" />
                           </div>
                           <div className="carousel-caption d-md-block mt-0 pt-0">
                              <div className="d-flex flex-column bd-highlight">
                                 <div className="py-1 bd-highlight">
                                    <h3 className="mb-1" style={{color:"#121416"}}>Select Language..</h3>
                                    <p className="m-0 p-0">For best experience and  related content</p>
                                 </div>
                                 <div className="py-1 bd-highlight">
                                    <button type="button" className="btn btn-light-modal-active">English<i className="fal fa-times ms-1"></i></button>  
                                    <button type="button" className="btn btn-light-modal">हिंदी<i className="fal fa-times ms-1 d-none"></i></button> 
                                    <button type="button" className="btn btn-light-modal">मराठी<i className="fal fa-times ms-1 d-none"></i></button> 
                                    <button type="button" className="btn btn-light-modal">ગુજરાતી<i className="fal fa-times ms-1 d-none"></i></button>
                                    <button type="button" className="btn btn-light-modal">কাজ<i className="fal fa-times ms-1 d-none"></i></button>
                                    <button type="button" className="btn btn-light-modal">മലയാളം<i className="fal fa-times ms-1 d-none"></i></button>
                                    <button type="button" className="btn btn-light-modal">Mandarin<i className="fal fa-times ms-1 d-none"></i></button> 
                                    <button type="button" className="btn btn-light-modal">French<i className="fal fa-times ms-1 d-none"></i></button>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="modal-btn">
                        <button type="button" className="btn btn-orange-big">
                        coutinue
                        </button>
                        <button type="button" data-bs-toggle="modal"
                           data-bs-target="#ManuallyLocationModal" className="btn btn-outline-secondary border-0">Enter Manually</button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div
         className="modal windowmodal fade"
         id="ManuallyLocationModal"
         data-bs-backdrop="static"
         data-bs-keyboard="false"
         tabIndex="-1"
         aria-labelledby="staticBackdropLabel"
         aria-hidden="true"
         >
         <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
               <div className="modal-header">
                  <div
                     className="modal-close ml-auto"
                     data-bs-dismiss="modal"
                     aria-label="Close"
                     >
                     <img src="assets/images/Arrow-Left.svg" />
                  </div>
               </div>
               <div className="modal-body py-0">
                  <div className="d-flex flex-column bd-highlight">
                     <div className="p-2 bd-highlight">
                        <div className="d-flex flex-row bd-highlight justify-content-center">
                           <div className="bd-highlight align-self-center me-2"><i style={{color:"#E43F01"}} className="fal fa-map-marker-alt fa-2x"></i></div>
                           <div className="bd-highlight align-self-center">
                              <h3 className="mb-0 text-center" style={{color:"#E43F01"}}>Enter your city name</h3>
                           </div>
                        </div>
                     </div>
                     <div className="p-2 bd-highlight">
                        <div className="modelsearch">
                           <div className="input-group input-group-lg">
                              <span className="input-group-text" id="basic-addon1">
                              <i className="fal fa-search"></i>
                              </span>
                              <input type="text" className="form-control" placeholder="Enter your city name" aria-label="Enter your city name" aria-describedby="basic-addon1"/>
                           </div>
                           <div className="modelsearchlist">
                              <ul className="list-group list-group-flush">
                                 <li className="list-group-item">
                                    Pune
                                 </li>
                                 <li className="list-group-item">
                                    Punjab
                                 </li>
                                 <li className="list-group-item">
                                    Manipur
                                 </li>
                                 <li className="list-group-item">
                                    Managlore
                                 </li>
                                 <li className="list-group-item">
                                    Machilipatnam
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="modal-footer text-center">
                  <button type="button" className="btn btn-orange-big" data-bs-toggle="modal"
                     data-bs-target="#LoadingModal">
                  Continue
                  </button>
               </div>
            </div>
         </div>
      </div>
      <div
         className="modal windowmodal fade"
         id="LoadingModal"
         data-bs-backdrop="static"
         data-bs-keyboard="false"
         tabIndex="-1"
         aria-labelledby="staticBackdropLabel"
         aria-hidden="true"
         >
         <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
               <div className="modal-header">
                  <div
                     className="modal-close ml-auto"
                     data-bs-dismiss="modal"
                     aria-label="Close"
                     >
                     <img src="assets/images/Arrow-Left.svg" />
                  </div>
               </div>
               <div className="modal-body py-0 text-center">
                  <div className="d-flex flex-column bd-highlight">
                     <div className="p-2 bd-highlight">
                        <div className="LoadingModalimg">
                           <div className="LoadingModalimgview">
                              <img src="assets/images/spinner.svg"/>
                           </div>
                        </div>
                     </div>
                     <div className="p-2 bd-highlight">
                        <h3 style={{color: "#E43F01"}}>Loading..</h3>
                        <p style={{color: "#605E5C", fontSize: "14px"}}>
                           “You never get a second change to make first impression” 
                        </p>
                        <p style={{color: "#A19F9D",fontSize: "14px"}}>So hold on tight while we curate the best posts around you..</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div
         className="modal windowmodal fade"
         id="LanguagesLocationModal"
         data-bs-backdrop="static"
         data-bs-keyboard="false"
         tabIndex="-1"
         aria-labelledby="staticBackdropLabel"
         aria-hidden="true"
         >
         <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
               <div className="modal-header px-3 border-bottom">
                  <h5 className="modal-title">Personalize Yor Feed</h5>
                  <div
                     className="modal-close ml-auto"
                     data-bs-dismiss="modal"
                     aria-label="Close"
                     >
                     <img src="assets/images/Arrow-Left.svg" />
                  </div>
               </div>
               <div className="modal-body pt-1">
                  <div className="d-flex flex-column bd-highlight">
                     <div className="py-1 bd-highlight">
                        <div className="d-flex flex-row bd-highlight">
                           <div className="p-1 bd-highlight align-self-center">
                              <div className="unionicons">
                                 <img src="assets/images/Union.svg"/>
                              </div>
                           </div>
                           <div className="p-1 bd-highlight align-self-center">
                              <h6>Show posts only in..</h6>
                           </div>
                        </div>
                     </div>
                     <div className="py-1 bd-highlight">
                        <button type="button" className="btn btn-light-modal-active">English<i className="fal fa-times ms-1"></i></button>  
                        <button type="button" className="btn btn-light-modal">हिंदी<i className="fal fa-times ms-1 d-none"></i></button> 
                        <button type="button" className="btn btn-light-modal">मराठी<i className="fal fa-times ms-1 d-none"></i></button> 
                        <button type="button" className="btn btn-light-modal">ગુજરાતી<i className="fal fa-times ms-1 d-none"></i></button>
                        <button type="button" className="btn btn-light-modal">কাজ<i className="fal fa-times ms-1 d-none"></i></button>
                        <button type="button" className="btn btn-light-modal">മലയാളം<i className="fal fa-times ms-1 d-none"></i></button>
                        <button type="button" className="btn btn-light-modal">Arabic<i className="fal fa-times ms-1 d-none"></i></button> 
                     </div>
                     <div className="py-1 bd-highlight border-bottom mb-2">
                        <h6 className="mb-2"><small>More languages..</small></h6>
                     </div>
                     <div className="py-1 bd-highlight">
                        <h6>What moods would like to see ?</h6>
                     </div>
                     <div className="py-1 bd-highlight">
                        <button type="button" className="btn btn-light-modal-active">All</button>  
                        <button type="button" className="btn btn-light-modal">mumbaivibes</button> 
                        <button type="button" className="btn btn-light-modal">nightlife</button> 
                        <button type="button" className="btn btn-light-modal">couplespoint</button>
                        <button type="button" className="btn btn-light-modal">bikerace</button>
                        <button type="button" className="btn btn-light-modal">politics</button>
                     </div>
                     <div className="py-1 bd-highlight">
                        <h6><small>More Moods..</small></h6>
                     </div>
                  </div>
               </div>
               <div className="modal-footer">
                  <button type="button" className="btn btn-warning-small" data-bs-toggle="modal"
                     data-bs-target="#LanguagesListModal">Apply</button>
                  <button type="button" className="btn btn-light-small-modal"  data-bs-dismiss="modal">Reset to default</button>
               </div>
            </div>
         </div>
      </div>
      <div
         className="modal windowmodal fade"
         id="LanguagesListModal"
         data-bs-backdrop="static"
         data-bs-keyboard="false"
         tabIndex="-1"
         aria-labelledby="staticBackdropLabel"
         aria-hidden="true"
         >
         <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
               <div className="modal-header px-3 border-bottom">
                  <h5 className="modal-title">Personalize Yor Feed</h5>
                  <div
                     className="modal-close ml-auto"
                     data-bs-dismiss="modal"
                     aria-label="Close"
                     >
                     <img src="assets/images/Arrow-Left.svg" />
                  </div>
               </div>
               <div className="modal-body pt-1">
                  <div className="d-flex flex-column bd-highlight">
                     <div className="py-1 bd-highlight">
                        <div className="d-flex flex-row bd-highlight">
                           <div className="p-1 bd-highlight align-self-center">
                              <div className="unionicons">
                                 <img src="assets/images/Union.svg"/>
                              </div>
                           </div>
                           <div className="p-1 bd-highlight align-self-center">
                              <h6>Show posts only in..</h6>
                           </div>
                        </div>
                     </div>
                     <div className="py-1 bd-highlight">
                        <button type="button" className="btn btn-light-modal-active">English<i className="fal fa-times ms-1"></i></button>  
                        <button type="button" className="btn btn-light-modal">हिंदी<i className="fal fa-times ms-1 d-none"></i></button> 
                        <button type="button" className="btn btn-light-modal">मराठी<i className="fal fa-times ms-1 d-none"></i></button> 
                        <button type="button" className="btn btn-light-modal">ગુજરાતી<i className="fal fa-times ms-1 d-none"></i></button>
                     </div>
                     <div className="py-1 bd-highlight">
                        <div className="LanguagesListModalsearch">
                           <form>
                              <div className="row">
                                 <div className="col-sm-9 col-9 pe-0">
                                    <div className="input-group flex-nowrap">
                                       <span className="input-group-text">
                                       <i className="fal fa-search"></i>
                                       </span>
                                       <input type="text" className="form-control" placeholder="Search for Languages"/>
                                    </div>
                                 </div>
                                 <div className="col-sm-3 col-3 text-center px-2">
                                    <button type="submit" className="btn btn-search">Search</button>
                                 </div>
                              </div>
                           </form>
                           <div className="modelsearchlist d-none">
                              <ul className="list-group list-group-flush">
                                 <li className="list-group-item border-top-0 border-end-0 border-start-0">
                                    Malyalam
                                 </li>
                                 <li className="list-group-item">
                                    ગુજરાતી
                                 </li>
                                 <li className="list-group-item">
                                    தமிழ்நாடு
                                 </li>
                                 <li className="list-group-item">
                                    কাজ
                                 </li>
                                 <li className="list-group-item">
                                    मराठी
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="modal-footer text-center">
                  <button type="button" className="btn btn-orange-big" data-bs-toggle="modal"
                     data-bs-target="#SurveyModal">
                  Continue
                  </button>
               </div>
            </div>
         </div>
      </div>
      <div
         className="modal SurveyModal fade"
         id="SurveyModal"
         data-bs-backdrop="static"
         data-bs-keyboard="false"
         tabIndex="-1"
         aria-labelledby="staticBackdropLabel"
         aria-hidden="true"
         >
         <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
               <div className="modal-header px-3 border-bottom-0">
                  <div
                     className="modal-close ml-auto"
                     data-bs-dismiss="modal"
                     aria-label="Close"
                     >
                     <img src="assets/images/Arrow-Left.svg" />
                  </div>
               </div>
               <div className="modal-body py-1">
                  <div className="SurveyModallogo">
                     <div className="SurveyModallogoIcons">
                        <img src="assets/images/Localbol.svg"/>
                     </div>
                  </div>
                  <div className="SurveyModalbody">
                     <div className="d-flex flex-column bd-highlight">
                        <div className="p-1 bd-highlight">
                           <h2>Contribute to local bol</h2>
                        </div>
                        <div className="p-1 bd-highlight">
                           <p>Would you like to share some info about this place that other peoples might find useful ?</p>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="modal-footer pt-0">
                  <div className="d-flex flex-row bd-highlight">
                     <div className="py-2 px-1 bd-highlight flex-fill text-center">
                        <button type="button" className="btn btn-warning-small" data-bs-toggle="modal"
                           data-bs-target="#SurveyModalTwo">Take Survey</button>
                     </div>
                     <div className="py-2 px-1 bd-highlight flex-fill text-center">
                        <button type="button" className="btn btn-warning-small-light"  data-bs-dismiss="modal">I’ll do it later</button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div
         className="modal SurveyModal fade"
         id="SurveyModalTwo"
         data-bs-backdrop="static"
         data-bs-keyboard="false"
         tabIndex="-1"
         aria-labelledby="staticBackdropLabel"
         aria-hidden="true"
         >
         <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
               <div className="modal-header px-3 border-bottom-0 pb-2">
                  <h5 className="modal-title">Question 1<small> / 3</small> </h5>
                  <div
                     className="modal-close ml-auto"
                     data-bs-dismiss="modal"
                     aria-label="Close"
                     >
                     <img src="assets/images/Arrow-Left.svg" />
                  </div>
               </div>
               <div className="modal-body pb-1 pt-0">
                  <div className="d-none flex-column bd-highlight">
                     <div className="px-1 py-1 bd-highlight">
                        <div className="progress">
                           <div className="progress-bar bg-success" role="progressbar" style={{width: "5%"}} aria-valuenow="5" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                     </div>
                     <div className="px-1 py-1 bd-highlight">
                        <h6>1. Which night clubs are open in mumbai after 12 PM ?</h6>
                     </div>
                     <div className="px-1 py-1 bd-highlight">
                        <div className="d-flex flex-column bd-highlight">
                           <div className="px-2 py-1 bd-highlight">
                              <div className="form-check">
                                 <input className="form-check-input" type="radio" name="Question" id="Question1" value="option1" checked />
                                 <label className="form-check-label" for="Question1">
                                 Answer 1
                                 </label>
                              </div>
                           </div>
                           <div className="px-2 py-1 bd-highlight">
                              <div className="form-check">
                                 <input className="form-check-input" type="radio" name="Question" id="Question2" value="option2" />
                                 <label className="form-check-label" for="Question2">
                                 Answer 2
                                 </label>
                              </div>
                           </div>
                           <div className="px-2 py-1 bd-highlight">
                              <div className="form-check">
                                 <input className="form-check-input" type="radio" name="Question" id="exampleRadios3" value="option3" />
                                 <label className="form-check-label" for="exampleRadios3">
                                 Answer 3
                                 </label>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="d-none flex-column bd-highlight">
                     <div className="px-1 py-1 bd-highlight">
                        <div className="progress">
                           <div className="progress-bar bg-success" role="progressbar" style={{width: "45%"}} aria-valuenow="45" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                     </div>
                     <div className="px-1 py-1 bd-highlight">
                        <h6>2. Which night clubs are open in mumbai after 12 PM ?</h6>
                     </div>
                     <div className="px-1 py-1 bd-highlight">
                        <div className="d-flex flex-column bd-highlight">
                           <div className="px-2 py-1 bd-highlight">
                              <div className="form-check">
                                 <input className="form-check-input" type="checkbox"  id="Question1" value="option1" checked />
                                 <label className="form-check-label" for="Question1">
                                 Answer 1
                                 </label>
                              </div>
                           </div>
                           <div className="px-2 py-1 bd-highlight">
                              <div className="form-check">
                                 <input className="form-check-input" type="checkbox"  id="Question2" value="option2"/>
                                 <label className="form-check-label" for="Question2">
                                 Answer 2
                                 </label>
                              </div>
                           </div>
                           <div className="px-2 py-1 bd-highlight">
                              <div className="form-check">
                                 <input className="form-check-input" type="checkbox"  id="exampleRadios3" value="option3"/>
                                 <label className="form-check-label" for="exampleRadios3">
                                 Answer 3
                                 </label>
                              </div>
                           </div>
                           <div className="px-2 py-1 bd-highlight">
                              <div className="form-check">
                                 <input className="form-check-input" type="checkbox"  id="Question4" value="option1"/>
                                 <label className="form-check-label" for="Question1">
                                 Answer 4
                                 </label>
                              </div>
                           </div>
                           <div className="px-2 py-1 bd-highlight">
                              <div className="form-check">
                                 <input className="form-check-input" type="checkbox"  id="Question5" value="option2"/>
                                 <label className="form-check-label" for="Question2">
                                 Answer 4
                                 </label>
                              </div>
                           </div>
                           <div className="px-2 py-1 bd-highlight">
                              <div className="form-check">
                                 <input className="form-check-input" type="checkbox"  id="Question6" value="option3"/>
                                 <label className="form-check-label" for="Question6">
                                 Answer 5
                                 </label>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="d-flex flex-column bd-highlight">
                     <div className="px-1 py-1 bd-highlight">
                        <div className="progress">
                           <div className="progress-bar bg-success" role="progressbar" style={{width: "45%"}} aria-valuenow="45" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                     </div>
                     <div className="px-1 py-1 bd-highlight">
                        <h6>3. Which night clubs are open in mumbai after 12 PM ?</h6>
                     </div>
                     <div className="px-1 py-1 bd-highlight">
                        <div className="mt-3">
                           <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="modal-footer pt-0">
                  <div className="d-flex flex-row bd-highlight">
                     <div className="py-2 px-1 bd-highlight flex-fill text-center">
                        <button type="button" className="btn btn-warning-regular" data-bs-toggle="modal"
                           data-bs-target="#SurveyModalDone">Continue</button>
                     </div>
                     <div className="py-2 px-1 bd-highlight flex-fill text-center">
                        <button type="button" className="btn btn-warning-regular-light" data-bs-dismiss="modal">Skip</button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div
         className="modal SurveyModal fade"
         id="SurveyModalDone"
         data-bs-backdrop="static"
         data-bs-keyboard="false"
         tabIndex="-1"
         aria-labelledby="staticBackdropLabel"
         aria-hidden="true"
         >
         <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
               <div className="modal-header px-3 border-bottom-0">
                  <div
                     className="modal-close ml-auto"
                     data-bs-dismiss="modal"
                     aria-label="Close"
                     >
                     <img src="assets/images/Arrow-Left.svg" />
                  </div>
               </div>
               <div className="modal-body py-1">
                  <div className="SurveyModallogo">
                     <div className="SurveyModallogoIcons">
                        <img src="assets/images/Partypopper.svg"/>
                     </div>
                  </div>
                  <div className="SurveyModalbody">
                     <div className="d-flex flex-column bd-highlight">
                        <div className="p-1 bd-highlight">
                           <h2>Thank You!!</h2>
                        </div>
                        <div className="p-1 bd-highlight">
                           <p>That’s it, we don’t have anything else to say because we are not very good in praising.</p>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="modal-footer pt-0">
                  <div className="d-flex flex-row bd-highlight">
                     <div className="py-2 px-1 bd-highlight flex-fill text-center">
                        <button type="button" className="btn btn-warning-small" data-bs-toggle="modal"
                           data-bs-target="#SurveyModalTwo">More survey</button>
                     </div>
                     <div className="py-2 px-1 bd-highlight flex-fill text-center">
                        <button type="button" className="btn btn-warning-small-light"  data-bs-dismiss="modal">Close</button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
    </React.Fragment>
}