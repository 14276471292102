import React from 'react'
import { MdLocationPin, MdPlayCircle } from "react-icons/md";
import { isYouTubeVideo } from '../functions/helper';

const VideoCard = ({ videoSource}) => {
    const videoRef = React.createRef();
    const videoPlayerRef = React.createRef();
    const handlePlayVideo = (e) => {
        e.stopPropagation();
        if (videoRef.current !== null) {
            if (videoRef.current.paused) {
                videoPlayerRef.current.style.display = "none";
                videoRef.current.play();
                videoRef.current.setAttribute('controls', 'controls');
            } else {
                videoRef.current.pause();
                videoPlayerRef.current.style.display = "flex";
            }
        }
    }

    //const videoSource = "https://www.youtube.com/watch?v=QV4m-GAO8CE"

    const handleOnVideoPlay = (e) => {
        videoPlayerRef.current.style.display = "none";
    }

    const handlePauseVideo = () => {
        videoPlayerRef.current.style.display = "flex";

        if (videoRef.current !== null) {
            if (!videoRef.current.paused) {
                videoPlayerRef.current.style.display = "flex";
                videoRef.current.pause();
            }
        }
    }

    return (
        isYouTubeVideo(videoSource) ?
            videoSource?.includes('be/')
                ?
                <iframe style={{minHeight:'300px'}} width="100%" className="post-media-content" src={"https://www.youtube.com/embed/" + videoSource?.split('e/')[1]} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>

                :
                <iframe style={{minHeight:'300px'}} width="100%" className="post-media-content" src={"https://www.youtube.com/embed/" + videoSource?.split("=")[1]} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
            :
            <div  style={{ position: "relative" }}>
                <div ref={videoPlayerRef} onClick={(e) => { handlePlayVideo(e) }} style={{ position: "absolute", backgroundColor: "white", height: 60, width: 60, borderRadius: 60, top: "50%", left: "50%", transform: "translate(-50%,-50%)", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <MdPlayCircle size={64} color="orangered" />
                </div>
                <video onPause={handlePauseVideo} onPlay={handleOnVideoPlay} controls onClick={() => { handlePauseVideo() }} className="img-fluid video-ele post-media-content" preload="metadata" ref={videoRef} style={{ width: "100%", objectFit: "cover" }}>
                    <source src={videoSource + "#t=0.6"} type="video/mp4"></source>
                </video>

            </div>

    )
}

export default VideoCard