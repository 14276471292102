import React from 'react'

const Brand = ({customStyle}) => {
    return (
        <img alt='localbol'
            src={require("../assets/logo.png")}
            width="120"
            height="60"
            className="d-inline-block align-text-top"
            style={{ objectFit: 'cover',...customStyle }}

        />
    )
}

export default Brand