import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { MdAccountCircle, MdArrowForward, MdCloudUpload, MdComputer, MdEmail, MdInbox, MdLocationPin, MdOutlineMessage, MdPhone, MdVerifiedUser, MdWeb } from 'react-icons/md';
import IMAGE_PATH from '../assets';
import { Colors } from '../constants/colors';
import { emailRegex, phoneRegex, saveUser } from '../functions/helper';
import CommunityCard from './CommunityCard';
import CustomInput from './CustomInput';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import { customMultipartRequest, customRequest } from '../functions/request';
import { setShowAlertPopup } from '../redux/dataSlice';
import { useDispatch } from 'react-redux';
import { gradient } from '../constants/templateGradient';
import SpinnerOverlay from './OverlayLoader';



const DownloadConfirmation = ({ isOpen, onClose, postImageUrl, postId, dynamicName }) => {
    let [user, setUser] = React.useState(JSON.parse(localStorage.getItem('user')) ?? null);
    const [imagePreview, setImagePreview] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);
    const [imageRefrence, setImageRefrence] = useState(null);
    const [userPic, setUserPic] = useState(null);
    const [showTemplate, setShowTemplate] = useState(false)
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [imgError, setImgError] = useState(false)
    const [loading, setLoading] = useState(false)


    const dispatch = useDispatch()

    const [formData, setFormData] = useState({
        name: user?.full_name ? user?.full_name : "",
        mobile: user?.phone ? user?.phone : '',
        businessName: user?.business_name ? user?.business_name : '',
        alternateEmail: user?.alternate_email ? user?.alternate_email : "",
        website: user?.website ? user?.website : '',
        address: user?.alternate_address ? user?.alternate_address : '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const [profilePicture, setProfilePicture] = useState(null)



    const handleProfileImage = (e) => {
        const file = e.target.files[0];

        if (file) {
            // Read the selected file and create a preview URL
            const reader = new FileReader();

            reader.onload = (e) => {
                setImagePreview(e.target.result);
            };

            reader.readAsDataURL(file);
            setProfilePicture(file);
        }

    };

    const getImageRef = (imageRef) => {
        setImageRefrence(imageRef)
        // return imageRef
    }

    async function refreshUserDetails() {
        customRequest('user/userdetail', { user_id: user?.id }).then((res) => {
            if (!!res.user) {
                saveUser(res.user)
                setUser(res.user)
            }

        });
    }

    function showAlertPopup(message) {
        dispatch(
            setShowAlertPopup({
                show: true,
                title: 'Alert',
                message,
            })
        );
    }

    const handleSelectTemplate = (elm) => {
        setSelectedTemplate(elm)
    }

    const handleSubmit = async () => {

        if (!selectedTemplate?.id) {
            showAlertPopup("Please choose template")
            return;
        }
        if (!user && !formData?.name) {
            showAlertPopup("Please enter your name")
            return;
        }
        if (!formData.mobile) {
            showAlertPopup("Please enter phone number")
            return
        }


        if (!phoneRegex.test(formData.mobile)) {
            showAlertPopup("Invalid mobile!")
            return;
        }


        if (!user && !formData?.alternateEmail) {
            showAlertPopup("Please enter your email")
            return;
        }
        if (formData?.alternateEmail && !emailRegex.test(formData.alternateEmail)) {
            showAlertPopup("Invalid email!")
            return;
        }
        setLoading(true)
        try {
            let element = imageRefrence?.current;
            element.style.display = "block"
            const canvas = await html2canvas(element);
            const data = canvas.toDataURL('image/jpg');
            const dynamicName = postImageUrl?.substring(postImageUrl.lastIndexOf('/') + 1);
            saveAs(data, dynamicName)
            let reqformdata = new FormData();
            reqformdata.append('post_id', postId);
            reqformdata.append('user_id', user ? user?.id : null);
            reqformdata.append('isGuest', user ? false : true);
            reqformdata.append('name', formData?.name);
            reqformdata.append('alternateEmail', formData?.alternateEmail);
            reqformdata.append('mobile', formData?.mobile);
            reqformdata.append('businessName', formData?.businessName);
            reqformdata.append('message', formData?.message)
            reqformdata.append('address', formData?.address)
            reqformdata.append('website', formData?.website)
            reqformdata.append('profile', profilePicture)
            customMultipartRequest('post/new-download', reqformdata)
                .then(async (res) => {
                    if (res.status === 'success' && user) {
                        await refreshUserDetails()
                    }
                })
                .catch(e => {
                    console.log(e)
                }
                )
                
        } catch (error) {
            dispatch(
                setShowAlertPopup({
                    show: true,
                    title: "Alert",
                    message: "Something went wrong",
                })
            );

        } finally {
            setSelectedTemplate(null)
            onClose()
            setLoading(false)
            setImagePreview(null)
        }



    }

    async function getBase64ImageFromUrl(url) {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    const base64data = reader.result;
                    resolve(base64data);
                };
                reader.onerror = (error) => {
                    reject(error);
                };
            });
        } catch (error) {
            throw error;
        }
    }






    useEffect(() => {
        if (!!user?.user_detail?.profile_pic) {
            getBase64ImageFromUrl(user?.user_detail?.profile_pic)
                .then((base64data) => {
                    console.log("user",base64data)
                    setUserPic(base64data)
                    // You can use the base64data wherever you need it
                })
                .catch((error) => {
                    //    console.error('Error fetching image:', error);
                });
        }
        const loadImage = async () => {
            getBase64ImageFromUrl(postImageUrl)
                .then((base64data) => {
                    setImageSrc(base64data);
                    // You can use the base64data wherever you need it
                })
                .catch((error) => {
                    //  console.error('Error fetching image:', error);
                });
        }

        loadImage()

    }, [])




    if (!isOpen) return null;


    const modalStyle = {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: '999',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        //  height:'80%'
        //   maxHeight:'80vh',

    };


    const backdropStyle = {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: '999',
        backdropFilter: 'blur(2px)',



    };

    const contentStyle = {
        backgroundColor: Colors.secondary,
        padding: '20px',
        Width: '80vw',
        zIndex: '999',
        borderRadius: '8px',
        //position:"fixed"




    };


    return (
        <div >
            <CommunityCard selectedTemplate={selectedTemplate} getImageRef={getImageRef} imageSrc={imageSrc} profilePic={imagePreview ? imagePreview : userPic} data={formData} />
            <div >

                <div style={backdropStyle}>
                    <div style={modalStyle}>

                        <div style={contentStyle}>

                            {!showTemplate && <>

                                <div className='my-2 d-flex'>

                                    {/* <div id="mediadisplay" style={{ margin: "auto", padding: 20, width: '100px', height: '100px', borderRadius: '50px',zIndex:1000}}></div> */}
                                    <div>
                                        <input type="file" style={{ display: "none" }} id="selectMedia" name="media" onChange={handleProfileImage} />
                                        {imagePreview ?
                                            <img
                                                src={imagePreview}
                                                style={{ width: '80px', height: '80px', borderRadius: '40px', backgroundColor: Colors.white, marginRight: 10, objectFit: "contain" }}
                                            />
                                            :
                                            !imgError && user?.user_detail?.profile_pic ? (
                                                <img
                                                    onError={(e) => setImgError(true)}
                                                    src={user?.user_detail?.profile_pic}
                                                    style={{ width: '80px', height: '80px', borderRadius: '40px', backgroundColor: Colors.white, marginRight: 10, objectFit: "contain" }}
                                                />
                                            ) : (
                                                <img
                                                    src={IMAGE_PATH.Logo}
                                                    style={{ width: '80px', height: '80px', borderRadius: '40px', backgroundColor: Colors.white, marginRight: 10, objectFit: "contain" }}
                                                />
                                            )
                                        }
                                        <label className='btn ' htmlFor="selectMedia" onClick={() => { }} style={{ maxWidth: '300px', border: `1px solid ${Colors.primary}`, padding: "4px 10px", borderRadius: '150px', alignItems: "center", justifyContent: "center", fontSize: 12, color: Colors.primary, backgroundColor: "white", whiteSpace: "nowrap", cursor: "pointer" }}>
                                            <MdCloudUpload color={Colors.primary} style={{ marginRight: '4px' }} />

                                            Change
                                        </label>
                                    </div>
                                    <span style={{ cursor: 'pointer', fontSize: 25, marginLeft: 'auto', marginTop: -20 }} onClick={onClose}>
                                        &times;
                                    </span>


                                </div>

                                <div className='d-flex space-between' style={{}} >
                                    <CustomInput className={"mr-2"} type="text" placeholder="Name" value={formData.name} name={'name'}
                                        onChange={handleChange} />
                                    <CustomInput icon={<MdPhone color={Colors.primary} />} type="number" placeholder="Mobile*" value={formData.mobile}
                                        name={'mobile'} onChange={handleChange} />

                                </div>
                                {/* <div className='d-flex space-between mt-2 ' style={{}} >
                                    <CustomInput customStyle={{ marginRight: "6px" }} icon={<MdPhone color={Colors.primary} />} className="mr-2" type="number" placeholder="Mobile 1*" value={formData.mobile}
                                        name={'mobile'} onChange={handleChange} />
                                   
                                </div> */}
                                <div>
                                    <CustomInput icon={<MdComputer color={Colors.primary} />} type={'text'} placeholder='Enter Your Business/profession' className="mt-2" value={formData.businessName} name={'businessName'}
                                        onChange={handleChange} />
                                    <CustomInput icon={<MdLocationPin color={Colors.primary} />} type={'text'} placeholder='Address' className="mt-2" value={formData.address}
                                        name={'address'} onChange={handleChange} />

                                    <CustomInput icon={<MdEmail color={Colors.primary} />} type={'email'} placeholder='Alternate Email' className="mt-2" value={formData.alternateEmail}
                                        name={'alternateEmail'} onChange={handleChange} />

                                    <CustomInput icon={<MdWeb color={Colors.primary} />} type={'url'} placeholder='Website Address' className="mt-2" value={formData.website}
                                        name={'website'} onChange={handleChange} />


                                    <CustomInput icon={<MdOutlineMessage color={Colors.primary} />} type={'text'} placeholder='Write a message' className="mt-2" value={formData.message}
                                        name={'message'} onChange={handleChange} />

                                </div>
                                <div className='text-center mt-2 '>
                                    {/* <button onClick={onClose} className="btn-secondary btn rounded mr-2" style={{ color: Colors.white }}>Cancel</button> */}
                                    <button disabled={formData?.mobile ? false : true} onClick={() => setShowTemplate(true)} className="btn-secondary btn rounded mr-2" style={{ color: Colors.white }}>Choose Template</button>
                                    <button  onClick={handleSubmit} className="btn rounded " style={{ backgroundColor: Colors.primary, color: Colors.white, alignSelf: 'center' }}>Download</button>
                                </div>
                            </>
                            }
                            {showTemplate &&

                                <div className="d-flex flex-column justify-content-between" style={{ maxWidth: '400px', width: "80vw" }}>
                                    <p className='text-center'>Choose Template</p>
                                    <div style={{ height: "400px", overflow: 'scroll' }}>
                                        {gradient.map(elm => (
                                            <div onClick={() => handleSelectTemplate(elm)}
                                                className={`my-2 ${selectedTemplate?.id === elm.id ? 'selected' : ''}`} // Add a 'selected' class when the element is selected

                                                key={elm.id}
                                                style={{
                                                    width: '100%', height: '100px',
                                                    background: `linear-gradient(45deg,${elm.gradient.join(', ')})`,
                                                }}>
                                            </div>
                                        ))}
                                    </div>
                                    <button onClick={() => setShowTemplate(false)} className="btn rounded" style={{ backgroundColor: Colors.primary, color: Colors.white, alignSelf: 'center', display: 'flex', alignItems: 'center' }}>
                                        <p className='m-0'>Continue</p>
                                        <MdArrowForward style={{ marginLeft: '6px' }} />
                                    </button>

                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {loading && <SpinnerOverlay/>}
        </div>
    );
};

export default DownloadConfirmation;