import React, { useEffect, useState } from 'react'

const useVerify = () => {
    const [loading, setLoading] = useState(true)
    const [isVerified, setIsVerified] = useState(false)

    useEffect(() => {
     
    }, [])
    

  return {isVerified,loading }
}

export default useVerify
