import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { customMultipartRequest, customRequest, customRequestGET } from "../functions/request";
import { MdPinDrop, MdImage, MdMusicVideo, MdAudiotrack, MdLink, MdPoll, MdChevronLeft } from "react-icons/md";
import { imageRootlink, videoRootlink } from "../functions/link";
import { useDispatch } from "react-redux";
import { setShowAlertPopup } from "../redux/dataSlice";
import { getLoggedUser, isValidURL } from "../functions/helper";
import { Colors } from "../constants/colors";

export default function EditPostScreen() {
    let navigate = useNavigate();
    let params = useParams();
    let [user, setUser] = React.useState(JSON.parse(localStorage.getItem('user')))
    const locationref = useLocation();
    let [languages, setLanguages] = React.useState(null);
    let [location, setLocation] = React.useState('');
    let [selectedLanguage, setSelectedLanguage] = React.useState('');
    let [media, setMedia] = React.useState(null);
    let [title, setTitle] = React.useState('');
    let [description, setDescription] = React.useState('');
    let [selectedMediaType, setSelectedMediaType] = React.useState('');

    let [moods, setMoods] = React.useState(null);
    let [selectedMood, setSelectedMood] = React.useState('');
    let [postlink, setPostLink] = React.useState('');
    let [selectedSource, setSelectedSource] = React.useState('');
    let [postSourceLink, setPostSourceLink] = React.useState('');
    let [postid, setPostid] = React.useState('');
    const [postMedia, setPostMedia] = React.useState(null);
    let [isMediaLinkSelected, setIsMediaLinkSelected] = React.useState(false);

    const dispatch = useDispatch();


    const [imageError, setImageError] = React.useState(false)




    const handleImageError = () => {
        //  console.log('error in image')
        setImageError(true)

    }

    const handleInput = (e) => {
        if (e.target.name == "title") {
            setTitle(e.target.value);
        } else if (e.target.name == "description") {
            setDescription(e.target.value);
        }
        else if (e.target.name == "media") {
            let element;
            if (selectedMediaType === "image") {
                element = document.createElement('img');
            } else if (selectedMediaType === "video") {
                element = document.createElement('video');
                element.setAttribute('controls', 'true');
                // element.setAttribute('')
            }


            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = () => {
                element.src = reader.result;
                element.setAttribute('style', "width:calc(100vw - 40px);height:160px;object-fit:cover;border-radius:20px;")
                //  console.log(reader.result);
                document.getElementById('mediadisplay').innerHTML = '';
                document.getElementById('mediadisplay').append(element);
            }
            setMedia(
                e.target.files[0]
            )
        }
        // else if (e.target.name == "media") {
        //     const reader = new FileReader();
        //     reader.onload = () => {
        //         let img = document.createElement('img');
        //         reader.readAsDataURL(e.target.files[0]);
        //         img.src = reader.result;
        //         document.getElementById('mediadisplay').appendChild(img);

        //     }
        //     setMedia(
        //         e.target.files[0]
        //     )

        // }
    };

    const handleGetLanguage = async () => {
        customRequest('post/languages').then((res) => {
            setLanguages(
                res.languages.map((lang) => {
                    return (
                        <div key={lang.id} onClick={() => { setSelectedLanguage(lang.id) }} style={{ border: selectedLanguage == lang.id ? "1px solid orangered" : "1px solid gray", padding: "2px 6px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedLanguage == lang.id ? "orangered" : "gray", backgroundColor: selectedLanguage == lang.id ? "white" : "rgba(0,0,0,0.1)", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                            {lang.language_name}
                        </div>
                    )
                })
            )
        })

        customRequest('post/moods', { language_id: selectedLanguage }).then((res) => {
            setMoods(
                res.moods.map((mood) => {
                    return (
                        <div className="mt-2" key={mood.id} onClick={() => { setSelectedMood(mood.id) }} style={{ border: selectedMood == mood.id ? "1px solid orangered" : "1px solid gray", padding: "2px 6px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedMood == mood.id ? "orangered" : "gray", backgroundColor: selectedMood == mood.id ? "white" : "rgba(0,0,0,0.1)", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                            {mood.mood_name}
                        </div>
                    )
                })
            )
        })

    }




    const handleCreatePost = () => {




        if (location === undefined || location === null || location === "") {
            dispatch(
                setShowAlertPopup(
                    {
                        show: true,
                        title: "Alert",
                        message: "Please select location to post.",
                    }
                )
            )
            return;
        }

        if (selectedMediaType === 'text' && (!title || !description)) {
            dispatch(
                setShowAlertPopup({
                    show: true,
                    title: 'Alert',
                    message: 'Please enter post title or descriptions.',
                })
            );
            return;
        }

        if (selectedMediaType === "link" && !media) {
            dispatch(
                setShowAlertPopup({
                    show: true,
                    title: 'Alert',
                    message: 'Please provide the link.',
                })
            );
            return;
        }
        if (postlink && !isValidURL(postlink)) {
            dispatch(
                setShowAlertPopup(
                    {
                        show: true,
                        title: "Alert",
                        message: "Please provide the valid link.",
                    }
                )
            )
            return;
        }
        //  console.log(location)


        //  console.log('media',media)





        let user = localStorage.getItem('user');
        user = JSON.parse(user);
        let formdata = new FormData();
        formdata.append('location', location);
        formdata.append('language_id', selectedLanguage);
        formdata.append('title', title);
        formdata.append('description', description)
        formdata.append('source', selectedSource);
        formdata.append('mood_id', selectedMood);
        formdata.append('visible', 0);
        formdata.append('media', media);
        formdata.append('user_id', user.id);
        formdata.append('source_link', postSourceLink);
        formdata.append('postid', postid);

        // console.log('update',formdata);

        customMultipartRequest('post/updatepost', formdata).then((res) => {
            if (res.message == 'Given data was invalid') {
                dispatch(
                    setShowAlertPopup(
                        {
                            show: true,
                            title: "Alert",
                            message: "Given data was invalid.",
                        }
                    )
                )
            } else {
                dispatch(
                    setShowAlertPopup(
                        {
                            show: true,
                            title: "Alert",
                            message: "Post updated successfully.",
                        }
                    )
                )
                navigate('/home')
            }
        })
    };


    const getPostToBeEdited = () => {
        customRequest('post/singlepost/' + params.postid).then((res) => {
            //  console.log(res)
            const post = res.post;
            setSelectedLanguage(post.language_id);
            setPostid(post.id);
            setSelectedMood(post.mood.id);
            setSelectedSource(post.source)
            // alert(post.contents[0].description)
            setTitle(post.contents[0].title)
            setDescription(post.contents[0].description);
            setLocation(post.location.locationMaster.location_name);
            setPostSourceLink(post.source_link);
            setPostMedia(post.media ? post.media[0] : null);
            setSelectedMediaType(post?.media?.length !== 0 ? post?.media[0]?.type : "text")
            if (post?.media[0]?.type === 'link') {
                setPostLink(post.media[0].path)
            }
        })
    }

    React.useEffect(() => {
        getPostToBeEdited();

    }, [])

    React.useEffect(() => {
        handleGetLanguage();

    }, [selectedMood, selectedLanguage])

    const handleSelectMediaTypeText = () => {

        setSelectedMediaType('text')
        setIsMediaLinkSelected(false)

        // console.log(selectedMediaType,postMedia)
        // if (!postMedia) {
        //     setSelectedMediaType('text')

        // }
        // else {
        //     dispatch(
        //         setShowAlertPopup({
        //             show: true,
        //             title: 'Alert',
        //             message: "Posted media or link can't be text.",
        //         })
        //     );
        // }

    }

    const handlePostLink = (t) => {
        setPostLink(t.target.value);
        setMedia(t.target.value)
        setSelectedMediaType('link')
        // console.log(media)
    }



    return (
        <div className="main_warp" style={{ backgroundColor: Colors.white }}>
            <div className="np__header" style={{ position: "relative", justifyContent: "space-between" }}>
                <MdChevronLeft style={{ fontSize: 32, cursor: "pointer" }} onClick={() => { navigate(-1) }} />
                <span style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)", fontFamily: "Open Sans", fontWeight: "bold" }}>Update Post</span>
                <button style={{ border: "none", padding: "2px 10px", fontSize: 14, fontFamily: "Open Sans", borderRadius: 60, background: "orangered", color: "white" }} onClick={() => { handleCreatePost() }}>Update</button>
            </div>

            <div style={{ display: "flex", alignItems: "center", padding: "0 12px", marginTop: 12 }}>
                <img onError={handleImageError}
                    alt='user_image'
                    src={imageError ? `${process.env.PUBLIC_URL}/profile_image.png` : `${imageRootlink}/profilepic/${user?.profile_pic}`} style={{ width: "46px", height: "46px", borderRadius: 40 }} />
                <div style={{ margin: "10px 12px" }}>
                    <h5 style={{ fontSize: "15px", lineHeight: "1px", fontFamily: "Open Sans", textTransform: 'capitalize' }}>{user?.full_name}</h5>
                    {!!location && <div style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
                        <MdPinDrop color="orangered" size={12} />
                        <span style={{ border: "1px solid orangered", padding: "2px 6px", borderRadius: 60, marginLeft: 4, alignItems: "center", justifyContent: "center", fontSize: 10, color: "orangered", backgroundColor: "white", whiteSpace: "nowrap", }}>{location}</span>

                        {/* <span style={{ fontSize: 12, fontFamily: "Open Sans", color: "orangered", marginLeft: 4 }}>Add Location</span> */}
                    </div>}
                    {/* <div style={{ display: "flex", alignItems: "center", overflowX: "auto", width: "calc(100vw - 86px)"  }}>
                      {  user.user_detail.current_city && <div onClick={() => { setLocation(user.user_detail.current_city) }} style={{ border: location == user.user_detail.current_city ? "1px solid orangered" : "1px solid gray", padding: "2px 6px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 10, color: location == user.user_detail.current_city ? "orangered" : "gray", backgroundColor: location == user.user_detail.current_city ? "white" : "rgba(0,0,0,0.1)", whiteSpace: "nowrap", cursor: "pointer" }}>
                            {user.user_detail.current_city}
                        </div>}
                        {
                            user?.user_detail?.other_cities?.split(',')?.filter(city=> city !=='null' && city !=='undefined' && city!=="")?.map(city => {
                                return (
                                      <div onClick={() => { setLocation(city) }} key={Math.random()} style={{ border: location == city ? "1px solid orangered" : "1px solid gray", padding: "2px 6px", borderRadius: 60, marginLeft: 4, alignItems: "center", justifyContent: "center", fontSize: 10, color: location == city ? "orangered" : "gray", backgroundColor: location == city ? "white" : "rgba(0,0,0,0.1)", whiteSpace: "nowrap", cursor: "pointer" }}>
                                        {city}
                                      </div>
                                    //    <div onClick={() => { setLocation(city) }} key={Math.random()} style={{ border: location == city ? "1px solid orangered" : "1px solid gray", padding: "2px 6px", borderRadius: 60, marginLeft: 4, alignItems: "center", justifyContent: "center", fontSize: 10, color: location == city ? "orangered" : "gray", backgroundColor: location == city ? "white" : "rgba(0,0,0,0.1)", whiteSpace: "nowrap", cursor: "pointer" }}>
                                    //    {city}
                                    //  </div>
                                )
                            })
                        }
                    </div> */}
                </div>
            </div>

            <div style={{ padding: "10px 12px" }}>
                <input type="text" name="title" onChange={handleInput} placeholder="Add title" style={{ border: "none", fontFamily: "Open Sans", fontSize: 20, fontWeight: 500 }} value={title} />
                <textarea name="description" rows={4} onChange={handleInput} placeholder="What’s on your mind?" style={{ border: "none", fontFamily: "Open Sans", fontSize: 16, width: "100%", marginTop: 12 }} value={description}></textarea>
            </div>

            <div id="mediadisplay" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {

                    postMedia && postMedia.type === "image"
                        ?
                        <img src={postMedia.path} style={{ width: "calc(100vw - 40px)", height: "160px", objectFit: "cover", borderRadius: "20px" }} />
                        :
                        postMedia && postMedia.type === "audio"
                            ?
                            <audio src={imageRootlink + "/post/" + postMedia.path}></audio>
                            :
                            postMedia && postMedia.type === "video"
                                ?
                                <video src={imageRootlink + "/post/" + postMedia.path} style={{ width: "calc(100vw - 40px)", height: "160px", objectFit: "cover", borderRadius: "20px" }}></video>
                                :
                                null

                }
            </div>
            <input type="file" onChange={handleInput} style={{ display: "none" }} id="selectMedia" name="media" />
            <h5 style={{ padding: "0 12px", fontSize: 12, lineHeight: "1px", marginTop: 16, marginBottom: 12 }}>Select Media</h5>

            <div style={{ display: "flex", alignItems: "center", overflowX: "auto" }}>
                <label onClick={handleSelectMediaTypeText} style={{ border: selectedMediaType === "text" ? "1px solid orangered" : "1px solid gray", padding: "4px 10px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedMediaType === "text" ? "orangered" : "gray", backgroundColor: selectedMediaType === "text" ? "white" : "rgba(0,0,0,0.1)", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                    Text
                </label>
                <label htmlFor="selectMedia" onClick={() => { setSelectedMediaType('image'); setIsMediaLinkSelected(false); }} style={{ border: selectedMediaType === "image" ? "1px solid orangered" : "1px solid gray", padding: "2px 6px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedMediaType === "image" ? "orangered" : "gray", backgroundColor: selectedMediaType === "image" ? "white" : "rgba(0,0,0,0.1)", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                    Picture
                </label>
                <label htmlFor="selectMedia" onClick={() => { setSelectedMediaType('audio'); setIsMediaLinkSelected(false); }} style={{ border: selectedMediaType === "audio" ? "1px solid orangered" : "1px solid gray", padding: "2px 6px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedMediaType === "audio" ? "orangered" : "gray", backgroundColor: selectedMediaType === "audio" ? "white" : "rgba(0,0,0,0.1)", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                    Audio
                </label>
                <label htmlFor="selectMedia" onClick={() => { setSelectedMediaType('video'); setIsMediaLinkSelected(false); }} style={{ border: selectedMediaType === "video" ? "1px solid orangered" : "1px solid gray", padding: "2px 6px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedMediaType === "video" ? "orangered" : "gray", backgroundColor: selectedMediaType === "video" ? "white" : "rgba(0,0,0,0.1)", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                    Video
                </label>
                <label onClick={() => { setSelectedMediaType('link') }} style={{ border: selectedMediaType === "link" ? "1px solid orangered" : "1px solid gray", padding: "4px 10px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedMediaType === "link" ? "orangered" : "gray", backgroundColor: selectedMediaType === "link" ? "white" : "rgba(0,0,0,0.1)", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                    Link
                </label>
            </div>
            {
                selectedMediaType === "link"
                    ?
                    <input value={postlink} onChange={handlePostLink} style={{ backgroundColor: "rgba(0,0,0,0.03)", border: "1px solid gray", padding: "6px 10px", borderRadius: 10, margin: "10px 10px" }} placeholder="Enter Media Link" />
                    :
                    null
            }

            <h5 style={{ padding: "0 12px", fontSize: 12, lineHeight: "1px", marginTop: 12, marginBottom: 12 }}>Select Language</h5>
            <div style={{ display: "flex", alignItems: "center", overflowX: "auto" }}>
                {
                    languages
                }
            </div>
            <h5 style={{ padding: "0 12px", fontSize: 12, lineHeight: "1px", marginTop: 16, marginBottom: 12 }}>Select Source</h5>
            <div style={{ display: "flex", alignItems: "center", overflowX: "auto" }}>
                <div onClick={() => { setSelectedSource("By Me") }} style={{ border: selectedSource == "By Me" ? "1px solid orangered" : "1px solid gray", padding: "2px 6px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedSource == "By Me" ? "orangered" : "gray", backgroundColor: selectedSource == "By Me" ? "white" : "rgba(0,0,0,0.1)", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                    By Me
                </div>
                <div onClick={() => { setSelectedSource("Forwarded") }} style={{ border: selectedSource == "Forwarded" ? "1px solid orangered" : "1px solid gray", padding: "2px 6px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedSource == "Forwarded" ? "orangered" : "gray", backgroundColor: selectedSource == "Forwarded" ? "white" : "rgba(0,0,0,0.1)", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                    Forwarded
                </div>
                <div onClick={() => { setSelectedSource("web") }} style={{ border: selectedSource == "web" ? "1px solid orangered" : "1px solid gray", padding: "2px 6px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedSource == "web" ? "orangered" : "gray", backgroundColor: selectedSource == "web" ? "white" : "rgba(0,0,0,0.1)", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                    Web
                </div>
            </div>

            {
                selectedSource == "web"
                    ?
                    <input onChange={(t) => { setPostSourceLink(t.target.value) }} value={postSourceLink} style={{ backgroundColor: "rgba(0,0,0,0.03)", border: "1px solid gray", padding: "6px 10px", borderRadius: 10, margin: "10px 10px" }} placeholder="Souce link" />
                    :
                    null
            }

            <h5 style={{ padding: "0 12px", fontSize: 12, marginTop: 12 }}>Select Bhagwan or Category</h5>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
                {
                    moods
                }
            </div>
            <div style={{ height: 100 }}></div>
        </div>
    );
}
