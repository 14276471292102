import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Brand from "../../components/Brand";
import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";
import { Colors } from "../../constants/colors";
import { customRequest } from "../../functions/request";

export default function RegisterVerifyOtpScreen() {
    let [OTP, setOTP] = React.useState("");
    let navigate = useNavigate();
    const location = useLocation();

    const handleInput = (e) => {
        if (e.target.name == "otp") {
            setOTP(e.target.value);
        }
    };

    // const handleLogin = () => {
    //     customRequest("auth/registerverifyotp", { phone: location.state.phone, otp: OTP }).then((res) => {
    //         if (res.status && res.status == "success") {
    //             navigate('/register', { state: { phone: location.state.phone } });
    //         } 
    //         // if (res.status == "notregiser") {

    //         // } else {
    //         //     alert(res.message);
    //         // }
    //     });
    //     // if (OTP != "") {
    //     // } else {
    //     //   customRequest("login", { phone: phoneNumber }).then((res) => {
    //     //     if (res.status && res.status == "success") {
    //     //       setShowOTP(true);
    //     //     } else {
    //     //       alert(res.message);
    //     //     }
    //     //   });
    //     // }
    // };

    return (
        <div style={{ height: "100vh", display: "flex", flexDirection: "column", zIndex: 1 }}>
            {/* <img src={require("../../assets/login-back.png")} style={{ position: "absolute", zIndex: 1, mixBlendMode: "soft-light" }} /> */}
            <img alt="logo" src={require("../../assets/main_logo.png")} style={{ height: "40vh", width: "100%", zIndex: 2, objectFit: "contain" }} />

            <div style={{ height: "56%", backgroundColor: "white", boxShadow: "0 -4px 10px rgba(0,0,0,0.1)", paddingTop: "18px", borderTopRightRadius: 20, borderTopLeftRadius: 20, marginTop: "auto", zIndex: 2 }}>
                <div style={{ padding: 16 }}>
                    <h5 style={{ color: Colors.purple500 }}>
                       Verify it's you
                    </h5>
                    <h5 style={{ color: "black" }}>Confirm the OTP send to <span style={{ color:Colors.purple500}}>tausif@mail.com</span></h5>

                    {/* <span style={{ fontSize: 14 }}>Kindly confirm the OTP sent your phone number ending with *****{location.state.phone.toString().substring(5)}</span> */}

                    <div >
                    <CustomInput placeholder={"Enter OTP"} className={"bg-light mt-2 "} />
                    <CustomButton label={"VERIFY"} className={"btn-block"} color={Colors.purple500} />

                    </div>
                   
                </div>
            </div>
        </div>
    );
}
