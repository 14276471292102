import React from 'react';

export default function FaqScreen(){
    return (
        <React.Fragment>
            <div className="main_warp">
      <nav
        className="navbar sticky-top navbar-light mob-header-bar pb-0"
        style={{display: 'block'}}
        >
        <div className="pt-3 loactiontext">
          <div className="d-flex flex-row bd-highlight w-100">
            <div className="p-1 bd-highlight align-self-center">
              <a className="left-icons" href="index.html">
              <i className="far fa-arrow-left fa-lg" style={{color: '#605e5c'}}></i>
              </a>
            </div>
            <div
              className="p-1 bd-highlight loactiontext align-self-center flex-grow-1"
              >
              <h5>Mumbai</h5>
            </div>
            <div className="p-1 bd-highlight align-self-center">
              <a className="search-iconstwo" href="#">
              <img
                width="25"
                height="25"
                src="assets/images/NavIcons.svg"
                />
              </a>
            </div>
          </div>
        </div>
      </nav>
      <div className="px-0">
        <div className="productdetail">
          <div className="productdetailsmallnav">
            <a href="Info.html">Info</a>
            <a href="Trending.html"
              >Trending Posts</a
              >
            <a href="Allposts.html">All Posts</a>
            <a className="productdetailsmallnavactive" href="Faq.html">FAQs</a>
            <a href="Authorities.html">Local Authorities</a>
          </div>
          <div className="productdetailsmallview" id="#FaQ">
            <div className="card mb-0">
              <div className="card-body px-0">
                <div className="col-sm-12 col-12">
                  <div className="d-flex flex-column bd-highlight">
                    <div className="pt-0 bd-highlight pb-2 mb-2 border-bottom">
                      <div
                        className="d-flex flex-row bd-highlight align-items-center"
                        >
                        <div className="bd-highlight flex-grow-1">
                          <h1>FAQ</h1>
                        </div>
                        <div className="bd-highlight">
                          <button
                            type="button"
                            className="btn btn-warning-light px-4"
                            >
                          Ask a question
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="p-0 bd-highlight faqbox">
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item border-bottom">
                          <h2 className="accordion-header mb-0" id="headingOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                              >
                            1. What sample question this could be?
                            </button>
                          </h2>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                            >
                            <div className="accordion-body">
                              <p>
                                Amet minim mollit non deserunt ullamco est sit
                                aliqua dolor do amet sint. Velit officia
                                consequat duis enim velit mollit. Exercitation
                                veniam consequat sunt nostrud amet.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item border-bottom">
                          <h2 className="accordion-header mb-0" id="headingTwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                              >
                            2. What sample question this could be?
                            </button>
                          </h2>
                          <div
                            id="collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                            >
                            <div className="accordion-body">
                              <p>
                                Amet minim mollit non deserunt ullamco est sit
                                aliqua dolor do amet sint. Velit officia
                                consequat duis enim velit mollit. Exercitation
                                veniam consequat sunt nostrud amet.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item border-bottom">
                          <h2 className="accordion-header mb-0" id="headingThree">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                              >
                            3. What sample question this could be?
                            </button>
                          </h2>
                          <div
                            id="collapseThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample"
                            >
                            <div className="accordion-body">
                              <p>
                                Amet minim mollit non deserunt ullamco est sit
                                aliqua dolor do amet sint. Velit officia
                                consequat duis enim velit mollit. Exercitation
                                veniam consequat sunt nostrud amet.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item border-bottom">
                          <h2 className="accordion-header mb-0" id="headingfourth">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapsefourth"
                              aria-expanded="false"
                              aria-controls="collapsefourth"
                              >
                            4. What sample question this could be?
                            </button>
                          </h2>
                          <div
                            id="collapsefourth"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingfourth"
                            data-bs-parent="#accordionExample"
                            >
                            <div className="accordion-body">
                              <p>
                                Amet minim mollit non deserunt ullamco est sit
                                aliqua dolor do amet sint. Velit officia
                                consequat duis enim velit mollit. Exercitation
                                veniam consequat sunt nostrud amet.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item border-bottom">
                          <h2 className="accordion-header mb-0" id="headingfive">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapsefive"
                              aria-expanded="false"
                              aria-controls="collapsefive"
                              >
                            5. What sample question this could be?
                            </button>
                          </h2>
                          <div
                            id="collapsefive"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingfive"
                            data-bs-parent="#accordionExample"
                            >
                            <div className="accordion-body">
                              <p>
                                Amet minim mollit non deserunt ullamco est sit
                                aliqua dolor do amet sint. Velit officia
                                consequat duis enim velit mollit. Exercitation
                                veniam consequat sunt nostrud amet.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item border-bottom">
                          <h2 className="accordion-header mb-0" id="headingsix">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapsesix"
                              aria-expanded="false"
                              aria-controls="collapsesix"
                              >
                            6. What sample question this could be?
                            </button>
                          </h2>
                          <div
                            id="collapsesix"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingsix"
                            data-bs-parent="#accordionExample"
                            >
                            <div className="accordion-body">
                              <p>
                                Amet minim mollit non deserunt ullamco est sit
                                aliqua dolor do amet sint. Velit officia
                                consequat duis enim velit mollit. Exercitation
                                veniam consequat sunt nostrud amet.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        </React.Fragment>
    )
}