import React from 'react'
import { useNavigate } from 'react-router-dom';

const JoinBhagwanLive = () => {
    const navigate = useNavigate();

  return (
    <button onClick={()=>navigate('/create-account')} className="btn  align-self-center rounded" style={{ borderRadius: '5px' ,backgroundColor:"#9650f2"}}>Join Bhagwan Live</button>

  )
}

export default JoinBhagwanLive
