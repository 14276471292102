import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function OtpScreen() {
    let navigate = useNavigate();
    let [location,setLocation] = React.useState('');

    const handleInput = (e) => {
        if (e.target.name == "location") {
            setLocation(e.target.value);
        } 
    };

    const handleLogin = () => {
        localStorage.setItem('location',location);
        navigate('/home',{replace:true});
    };

    return (
        <div className="container container-fluid" style={{ height: "100vh" }}>
            <div className="col-12 d-flex flex-column align-items-center justify-content-center h-100">
                <div className="card p-2 center-block">
                    <h6 className="text-center" style={{fontSize:"14px"}}>Enter Location You Want To Explore</h6>
                    <div className="form-group">
                        <input
                            type="text"
                            name="location"
                            placeholder="Enter Location"
                            className="form-control"
                            onChange={handleInput}
                        />
                    </div>
                    <button className="btn btn-primary" onClick={handleLogin}>
                        Set Locatiom
                    </button>
                    {/* <Link to="/register" className="text-center mt-2">New User ? Register Here</Link> */}
                </div>
            </div>
        </div>
    );
}
