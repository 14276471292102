import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { customRequest } from "../../functions/request";
import { setShowAlertPopup } from "../../redux/dataSlice";
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import Footer from "../../components/Footer";
import GoogleButton from "../../components/GoogleButton";
import { useGoogleLogin } from '@react-oauth/google';
import axios from "axios";
import CustomInput from "../../components/CustomInput";
import { Colors } from "../../constants/colors";
import { MdEmail, MdLock } from "react-icons/md";

export default function RegisterPhoneScreen() {
  let [phoneNumber, setPhoneNumber] = React.useState("");
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isAgreeWithTerms, setIsAgreeWithTerms] = React.useState(false);
  const [email, setEmail] = React.useState("")



  const handleInput = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleLogin = () => {
    customRequest("auth/registerphone", { phone: phoneNumber }).then((res) => {
      if (res.status && res.status == "success") {
        // setShowOTP(true);
        navigate("/registerverifyotp", { state: { phone: phoneNumber } })
      } else {
        dispatch(
          setShowAlertPopup(
            {
              show: true,
              title: "Alert",
              message: res.message,
            }
          )
        )
      }
    });
    // if (OTP != "") {
    //   customRequest("login", { phone: phoneNumber,otp :OTP }).then((res) => {
    //     if (res.status && res.status == "success") {
    //       localStorage.setItem('user',res.data)
    //       navigate('/home')
    //     } else {
    //       alert(res.message);
    //     }
    //   });
    // } else {

    // }
  };

  const handleTermsAndCondition = () => {
    dispatch(
      setShowAlertPopup(
        {
          show: true,
          title: "Alert",
          message: "Please accept terms & conditions.",
        }
      )
    )
  }


  const handleGoogleSignUp = (user) => {
    if (!!user) {
      navigate('/register', { state: { email: user.email, google_id: user.sub, full_name: user.name, email_verified: user.email_verified, picture: user.picture } })
    } else {
      alert('Some error occured')
    }
  }








  const handleGoogleAuth = useGoogleLogin({

    onSuccess: async tokenResponse => {
      // console.log(tokenResponse);
      // fetching userinfo can be done on the client or the server
      const userInfo = await axios
        .get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then(res => res.data).catch(e => {
          dispatch(
            setShowAlertPopup(
              {
                show: true,
                title: "Alert",
                message: "Something went wrong",
              }
            ))
        }
        )
        console.log(userInfo)
      if (userInfo) {
        handleGoogleSignUp(userInfo)
      }
    },
  })



  // React.useEffect(()=>{
  //   const script = document.createElement('script');

  //   script.src = "https://otpless.com/auth.js";
  //   script.async = true;

  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   }
  // },[])

  // React.useEffect(() => {
  //   const location = window.location.search;
  //   const params = new URLSearchParams(location);
  //   if (params.get('waId') != undefined && params.get('waId') != null) {
  //     localStorage.clear();
  //     const waId = params.get('waId');
  //     setIsLoading(true);
  //     customRequest('auth/register-with-whatsapp', { waId: waId }).then((res) => {
  //       console.log(res);
  //       if (res.status && res.status == "success") {
  //         navigate('/register', { state: { phone: res.phone } })
  //       } else {
  //         alert(res.message);
  //       }
  //     })
  //   }
  // }, []);



  // const otpless = () => {
  //   //@ts-ignore
  //   const waId = window.otplessWaId?.();
  //   if (waId) {
  //     localStorage.clear();
  //     // const waId = params.get('waId');
  //     setIsLoading(true);
  //     customRequest('auth/register-with-whatsapp', { waId: waId }).then((res) => {
  //       console.log(res);
  //       if (res.status && res.status == "success") {
  //         navigate('/register', { state: { phone: res.phone } })
  //       } else {
  //         alert(res.message);
  //       }
  //     })
  //   }
  //   //Once you signup/sign a user, you can redirect the user to your signup/signin flow.

  // }


  // React.useEffect(() => {
  //   setTimeout(() => {
  //     if (!document.getElementById('otpless').hasChildNodes()) {
  //       window.location.reload();
  //     }
  //   }, 1000)
  // }, [])


  return (
    <div className="mb-4" style={{
      width: "100vw",
      display: "flex",
      flexDirection: "column",
      paddingTop: 10,
    }} >

      {/* <img src={require("../../assets/login-back.png")} style={{ position: "absolute", zIndex: 1, mixBlendMode: "soft-light" }} /> */}
      <img src={require("../../assets/main_logo.png")} style={{ height: "40vh", width: "100%", zIndex: 2, objectFit: "contain" }} />

      <span style={{ color: "white", position: "absolute", top: 20, right: 20, fontSize: 14, fontFamily: "Open Sans", zIndex: 2 }}>Sign up Later</span>
      <div style={{ height: "62%", backgroundColor: "white", boxShadow: "0 -4px 10px rgba(0,0,0,0.1)", borderTopRightRadius: 20, borderTopLeftRadius: 20, marginTop: "auto", zIndex: 3 }}>
        <div style={{ padding: 16 }}>
          <h5>
            <b style={{ color: "orangered", lineHeight: 2 }}>Get Started</b><br />
            <b>Create an account now</b>
          </h5>
          <span style={{ fontSize: 14, color: "gray" }}>Create your account using your Google email account </span>

          <div className="mt-4" style={{ display: 'flex', flexDirection: 'column', maxWidth: '600px', margin: '0 auto' }}>

            {/* <div>
              <CustomInput value={email} onChange={(e)=>setEmail(e.target.value)} className={"rounded-pill"} icon={<MdEmail />} placeholder={"Email"} type={"text"} customStyle={{ borderWidth: 1, borderColor: Colors.silver }} />
              <div className="text-center mt-2">
                <button onClick={() => navigate('/register', { state: { email } })
                } className="btn btn-block text-white rounded-pill  " style={{ backgroundColor: Colors.primary }} >Continue</button>
                <p className="mt-2" style={{ color: Colors.primary }}>Or</p>
              </div>

            </div> */}


            <GoogleButton label={'Sign up with'} onClick={() => {
              if (!isAgreeWithTerms) {
                dispatch(
                  setShowAlertPopup(
                    {
                      show: true,
                      title: "Alert",
                      message: "Please accept terms & conditions.",
                    }
                  )
                )
                return;
              }
              handleGoogleAuth()
            }

            } />
            {/* <div style={{display:"none"}}>
            <GoogleLogin
              onSuccess={credentialResponse => { handleGoogleSignUp(credentialResponse.credential) }}
              onError={handleError}
            
              
            />
            </div> */}
            {/* <div id="otpless"></div> */}
          </div>

          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 12 }}>
            <p style={{ textAlign: "center" }}>Already registered ? <Link to="/" style={{ color: "orangered" }}>Click Here</Link></p>
          </div>


          {/* Your terms and conditions checkbox */}
          <div className="mb-4" style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>

            <label>
              <input
                id="specifyColorRadioBtn"
                type="checkbox"
                checked={isAgreeWithTerms}
                onChange={(e) => setIsAgreeWithTerms(e.target.checked)}
                style={{ marginRight: "5px" }}
              />
              I agree with the T&C and Privacy Policy
            </label>
          </div>
          {/* <Link to="/registerphone" className="text-center mt-2">New User ? Register Here</Link> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}
