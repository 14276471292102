import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { customMultipartRequest, customRequest, customRequestGET } from "../functions/request";
import { MdPinDrop, MdImage, MdMusicVideo, MdAudiotrack, MdLink, MdPoll, MdMyLocation } from "react-icons/md";
import { imageRootlink } from "../functions/link";
import { useDispatch, useSelector } from "react-redux";
import { setShowAlertPopup } from "../redux/dataSlice";

export default function ContestPostScreen() {
    let navigate = useNavigate();
    const rlocation = useLocation();
    const [location, setLocation] = React.useState('');
    let [user, setUser] = React.useState(JSON.parse(localStorage.getItem('user')))
    const locationref = useLocation();
    let [media, setMedia] = React.useState(null);
    let [title, setTitle] = React.useState('');
    let [description, setDescription] = React.useState('');
    const dispatch = useDispatch();

    let [moods, setMoods] = React.useState(null);
    let [postSourceLink, setPostSourceLink] = React.useState('');
    const currentLocation = useSelector(state => state.data.currentLocation);
    let [isMediaLinkSelected, setIsMediaLinkSelected] = React.useState(false);
    let [selectedMediaType, setSelectedMediaType] = React.useState(null);
    const [imageError, setImageError] = React.useState(false)




    const handleImageError = () => {
        //  console.log('error in image')
        setImageError(true)

    }

    const handleInput = (e) => {
        if (e.target.name == "title") {
            setTitle(e.target.value);
        } else if (e.target.name == "description") {
            setDescription(e.target.value);
        } else if (e.target.name == "media") {

            let element;
            if (selectedMediaType === "image") {
                element = document.createElement('img');
            } else if (selectedMediaType === "video") {
                element = document.createElement('video');
                element.setAttribute('controls', 'true');
                // element.setAttribute('')
            }

            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = () => {
                element.src = reader.result;
                element.setAttribute('style', "width:calc(100vw - 40px);height:160px;object-fit:cover;border-radius:20px;")
                //    console.log(reader.result);
                document.getElementById('mediadisplay').innerHTML = '';
                document.getElementById('mediadisplay').append(element);
            }

            setMedia(
                e.target.files[0]
            )
        }
    };


    const handleCreatePost = () => {


        if (location === undefined || location === null || location === "") {
            dispatch(
                setShowAlertPopup(
                    {
                        show: true,
                        title: "Alert",
                        message: "Please select location to post.",
                    }
                )
            )
            return;
        }

        let user = localStorage.getItem('user');
        user = JSON.parse(user);
        let formdata = new FormData();
        formdata.append('location', location);
        formdata.append('title', title);
        formdata.append('description', description)
        formdata.append('source', "contest");
        formdata.append('visible', 0);
        formdata.append('media', media);
        formdata.append('contest_id', rlocation.state.contest_id);

        customMultipartRequest('post/newpost', formdata).then((res) => {
            //  console.log(res);
            if (res.message == 'Given data was invalid') {
                // alert('Given data was invalid')
                dispatch(
                    setShowAlertPopup(
                        {
                            show: true,
                            title: "Alert",
                            message: "Given data was invalid",
                        }
                    )
                )
            } else {
                dispatch(
                    setShowAlertPopup(
                        {
                            show: true,
                            title: "Alert",
                            message: "Post created successfully",
                        }
                    )
                )
                // alert("post created successfully");
                navigate('/home')
            }
        })
    };

    const handleCurrentLocation = () => {
        navigator.geolocation.getCurrentPosition(position => {
            setLocation("current:" + position.coords.latitude + "," + position.coords.longitude)
        });
    }


    return (
        <div className="np" style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
            <div className="np__header" style={{ position: "relative", justifyContent: "space-between" }}>
                <span style={{ fontWeight: "700", cursor: "pointer" }} onClick={() => { navigate(-1) }}>X</span>
                <span style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)", fontFamily: "Open Sans", fontWeight: "bold" }}>Create Post</span>
                <button style={{ border: "none", padding: "2px 10px", fontSize: 14, fontFamily: "Open Sans", borderRadius: 60, background: "orangered", color: "white" }} onClick={() => { handleCreatePost() }}>Post</button>
            </div>

            <div style={{ display: "flex", alignItems: "center", padding: "0 12px", marginTop: 12 }}>
                {

                    <img
                        onError={handleImageError}
                        alt='user_image'
                        src={imageError ? `${process.env.PUBLIC_URL}/profile_image.png` : `${imageRootlink}/profilepic/${user?.profile_pic}`}
                        className="img-fluid"
                        style={{ width: "58px", height: "58px", borderRadius: 48 }}
                    />


                }
                <div style={{ margin: "10px 12px" }}>
                    <h5 style={{ fontSize: "15px", lineHeight: "1px", fontFamily: "Open Sans" }}>{user.full_name}</h5>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <MdPinDrop color="orangered" size={12} />
                        <span style={{ fontSize: 12, fontFamily: "Open Sans", color: "orangered", marginLeft: 4 }}>Add Location</span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", overflowX: "auto" }}>
                        <div onClick={() => { handleCurrentLocation(); }} key={Math.random()} style={{ border: location.includes('current:') ? "1px solid orangered" : "1px solid gray", padding: "4px 10px", borderRadius: 60, marginRight: 2, alignItems: "center", justifyContent: "center", fontSize: 10, color: location.includes('current:') ? "orangered" : "gray", backgroundColor: location.includes('current:') ? "rgba(255, 144, 25,0.1)" : "white", whiteSpace: "nowrap", cursor: "pointer" }}>
                            <MdMyLocation size={14} /> <span>My Location</span>
                        </div>
                        {
                            user.user_detail.current_city
                                ?
                                <div onClick={() => { setLocation(user.user_detail.current_city) }} style={{ border: location == user.user_detail.current_city ? "1px solid orangered" : "1px solid gray", padding: "4px 10px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 10, color: location == user.user_detail.current_city ? "orangered" : "gray", backgroundColor: location == user.user_detail.current_city ? "white" : "white", whiteSpace: "nowrap", cursor: "pointer" }}>
                                    {user.user_detail.current_city}
                                </div>
                                :
                                null
                        }
                        {
                            user?.user_detail?.other_cities?.split(',').map(city => {
                                if (city != "" && city != undefined) {
                                    return (
                                        <div onClick={() => { setLocation(city) }} key={Math.random()} style={{ border: location == city ? "1px solid orangered" : "1px solid gray", padding: "4px 10px", borderRadius: 60, marginLeft: 2, alignItems: "center", justifyContent: "center", fontSize: 10, color: location == city ? "orangered" : "gray", backgroundColor: location == city ? "rgba(255, 144, 25,0.1)" : "white", whiteSpace: "nowrap", cursor: "pointer" }}>
                                            {city}
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            </div>

            <div style={{ padding: "10px 12px" }}>
                <input type="text" name="title" onChange={handleInput} placeholder="Add title" style={{ border: "none", fontFamily: "Open Sans", fontSize: 20, fontWeight: 500 }} />
                <textarea name="description" rows={2} onChange={handleInput} placeholder="What’s on your mind?" style={{ border: "none", fontFamily: "Open Sans", fontSize: 16, width: "100%", marginTop: 12 }}></textarea>
            </div>

            <div id="mediadisplay" style={{ padding: 20 }}></div>
            <input type="file" onChange={handleInput} style={{ display: "none" }} id="selectMedia" name="media" />
            <h5 style={{ padding: "0 12px", fontSize: 12, lineHeight: "1px", marginTop: 16, marginBottom: 12 }}>Select Media</h5>

            <div style={{ display: "flex", alignItems: "center", overflowX: "auto" }}>
                {
                    rlocation.state.media_type === "image"
                        ?
                        <label htmlFor="selectMedia" onClick={() => { setSelectedMediaType('image'); setIsMediaLinkSelected(false); }} style={{ border: selectedMediaType === "image" ? "1px solid orangered" : "1px solid gray", padding: "4px 10px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedMediaType === "image" ? "orangered" : "gray", backgroundColor: "white", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                            Picture
                        </label>
                        :
                        rlocation.state.media_type === "audio"
                            ?
                            <label htmlFor="selectMedia" onClick={() => { setSelectedMediaType('audio'); setIsMediaLinkSelected(false); }} style={{ border: selectedMediaType === "audio" ? "1px solid orangered" : "1px solid gray", padding: "4px 10px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedMediaType === "audio" ? "orangered" : "gray", backgroundColor: "white", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                                Audio
                            </label>
                            :
                            rlocation.state.media_type === "video"
                                ?
                                <label htmlFor="selectMedia" onClick={() => { setSelectedMediaType('video'); setIsMediaLinkSelected(false); }} style={{ border: selectedMediaType === "video" ? "1px solid orangered" : "1px solid gray", padding: "4px 10px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedMediaType === "video" ? "orangered" : "gray", backgroundColor: "white", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                                    Video
                                </label>
                                :
                                rlocation.state.media_type === "link"
                                    ?
                                    <label onClick={() => { setSelectedMediaType('media_link'); setIsMediaLinkSelected(true); }} style={{ border: selectedMediaType === "media_link" ? "1px solid orangered" : "1px solid gray", padding: "4px 10px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedMediaType === "media_link" ? "orangered" : "gray", backgroundColor: "white", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                                        Link
                                    </label>
                                    :
                                    null

                }
            </div>

            {
                isMediaLinkSelected
                    ?
                    <input onChange={(t) => { setPostSourceLink(t.target.value) }} style={{ backgroundColor: "rgba(0,0,0,0.03)", border: "1px solid gray", padding: "6px 10px", borderRadius: 10, margin: "10px 10px" }} placeholder="Enter Media Link" />
                    :
                    null
            }

        </div>
    );
}
