import {configureStore} from "@reduxjs/toolkit";
import dataSlice from "./dataSlice";
import registrationSlice from "./registrationSlice";


export default configureStore(
    {
        reducer: {
            data: dataSlice,
            registrationData: registrationSlice
        }
    }
)