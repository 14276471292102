import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className='bg-light' style={{padding: "20px", position: "fixed", bottom: 0, left: 0, width: "100%", textAlign: "center",zIndex:10}}>
            <Link to="/about" style={{ color: "orangered", marginRight: "20px", }}>About</Link>
            <Link to="/terms-and-condition" style={{ color: "orangered",marginRight: "20px" }}>T&C</Link>
            <Link to="/privacy-policy" style={{ color: "orangered" }}>Privacy Policy</Link>

        </div>
    );
};

export default Footer;