import React from 'react'
import logo from '../assets/main_logo.png'
import JoinBhagwanLive from '../components/JoinBhagwanLive';
const AboutUs = () => {

    return (
        <div className='bg-light' style={{  width: '100vw', height: "100vh", display: "flex", flexDirection: "column", justifyContent: 'space-evenly' ,paddingTop:10,paddingBottom:20}} >
            <img src={logo} style={{ height: "30vh", width: "100%", zIndex: 2, objectFit: "contain" }}  ></img>
            <h3 className="text-center font-weight-bold"  >About Bhagwan Live</h3>
                    <p className="text-justify container">
                        Discover the power of spirituality and connect with God for holistic wellness. Bhagwan.Live is a platform that provides a range of spiritual resources to enhance your spiritual journey.
                </p>

            <h2 className="text-center container font-weight-bold" >Connect with God and Access Spiritual Resources</h2>
            <p className="text-justify container">
            Explore a world of spiritual wellness and divine connection through Bhagwan Live. Discover festival greetings, daily spiritual thoughts, bhajans, mantras, chanting, and prayers to enhance your holistic well-being.
            </p>
            <JoinBhagwanLive/>
        </div>
    )
}

export default AboutUs
