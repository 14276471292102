import React from 'react';
import './styles.css'
import { PostCard } from '../../components/postCard';
import { customRequest } from '../../functions/request';
import { Link, useNavigate } from 'react-router-dom';
import LanguageAndMoodModal from '../../components/moodsAndLanguageModal';
import { useDispatch, useSelector } from "react-redux";
import { setAddInPosts, setPostsInBulk, setSelectedMood, setShowAlertPopup } from '../../redux/dataSlice';
import { MdLocationPin, MdMyLocation } from 'react-icons/md';
import { BottomScrollListener } from 'react-bottom-scroll-listener';
import Spinner from "react-spinkit";
import Brand from '../../components/Brand';
import { getLoggedUser } from '../../functions/helper';
import { Colors } from '../../constants/colors';
import Hand from '../../assets/hand.svg';
import Today from './today';
import Posts from './posts';



let postOffset = 0

export default function Community() {
   let [posts, setPosts] = React.useState(null);
   const user = getLoggedUser()
   const [activeTab, setActiveTab] = React.useState('community');
   //Moods
   const allMoods = useSelector((state) => state.data.allMoods);
   const viewMoods = useSelector((state) => state.data.viewMoods);
   const selectedMood = useSelector(state => state.data.selectedMood);
   const selectedLanaguageId = useSelector((state) => state.data.selectedLanguageId);
   const slicePosts = useSelector((state) => state.data.posts);
   const [isBottomLoading, setIsBottomLoading] = React.useState(false);
   const [allPostLoaded, setAllPostLoaded] = React.useState(false);
   const dispatch = useDispatch();
   const [isLoading, setIsLoading] = React.useState(false);
   const [viewTodaySection, setViewTodaySection] = React.useState(false)
   const [moodBadges, setMoodBadges] = React.useState(null);
   // const  [postOffset, setPostOffset] = React.useState(0);
   const navItems = [
      { id: 'today', label: 'Home', onClick: () => setViewTodaySection(true) },
      { id: 'community', label: 'Community', onClick: () => setViewTodaySection(false) },
      { id: 'sadhana', label: 'Sadhana', onClick: () => handleRedirect('/contestlist') },
      { id: 'newPost', label: 'New Post', onClick: () => handleRedirect('/newpost') },
      { id: 'profile', label: 'Profile', onClick: () => handleRedirect('/myprofile') },
   ];

   //Location
   const currentLocation = useSelector(state => state.data.currentLocation);

   const navigate = useNavigate();

   const getPosts = (addPosts) => {
      let data = {
         language: selectedLanaguageId,
      };

      if (addPosts) {
         postOffset = postOffset + 10
         data.offset = postOffset;
      }

      if (selectedMood !== "All") {
         data.mood = selectedMood;
      } else if (viewMoods?.length > 0) {
         data.mood = viewMoods;
      }

      if (currentLocation !== "Everywhere") {
         if (currentLocation === "Nearme") {
            data.location = 'Nearme'
            data.locationCoords = JSON.parse(localStorage.getItem('location_coords'));
         } else {
            data.location = currentLocation;
            data.locationCoords = JSON.parse(localStorage.getItem('location_coords'));

         }
      }

      //console.log('page',data.offset,allPostLoaded)

      if (addPosts && !allPostLoaded) {
         setIsBottomLoading(true);
         customRequest('post/allposts', data).then((res) => {
            //  console.log('new post====>', res.posts.length,typeof res.posts.length)
            setIsBottomLoading(false);
            if (res.posts.length === 0) {
               setAllPostLoaded(true);
            }
            dispatch(setAddInPosts(res.posts));

         }).catch(e => {
         }).finally((() => {
            setIsBottomLoading(false);
            setIsLoading(false)
         }
         )
         )
      } else if (postOffset === 0) {
            setIsLoading(true)
            customRequest('post/allposts', data).then((res) => {
            dispatch(setPostsInBulk(res.posts));
         }).catch(e => {
            dispatch(
               setShowAlertPopup(
                  {
                     show: true,
                     title: "Alert",
                     message: "Some error occurred.",
                  }
               )
            )
         }).finally((() => {
            setIsBottomLoading(false);
            setIsLoading(false)
         }
         )
         )
      }

   }


   // const renderPosts = () => {

   //    if (isLoading) {
   //       setPosts(
   //          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", height: "70vh", flex: 1 }}>
   //             <div className="spinner-border" role="status" style={{ color: Colors.orange500, width: '5vh', height: '5vh' }}></div>
   //          </div>
   //       )
   //    } else if (slicePosts == undefined || slicePosts == null || slicePosts.length === 0 || slicePosts == "loading") {
   //       setPosts(
   //          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", height: "70vh", flex: 1 }}>
   //             <img src={require("../../assets/empty_post.png")} style={{ height: 240, width: 240, objectFit: "contain" }} />
   //             <p style={{ fontFamily: "Open Sans", fontSize: 15, textAlign: "center" }}>No any post.</p>
   //          </div>
   //       )
   //    } else {
   //       setPosts(
   //          slicePosts?.map((post) => {
   //             return (
   //                <PostCard key={post?.id} post={post} />
   //             )
   //          })
   //       );
   //    }
   // }
   const handleSelectHomeMood = (mood) => {
      dispatch(setSelectedMood(mood.id));
      dispatch(setPostsInBulk('loading'));
   }

   const getMoodBadges = () => {
      setMoodBadges(
         allMoods?.map((mood) => {
            if (viewMoods?.includes(mood.id)) {
               return (
                  <a key={mood.id} className={selectedMood == mood.id ? "Tabsnavactive" : ""} onClick={() => { handleSelectHomeMood(mood) }} style={{ cursor: "pointer" }}>{mood.mood_name}</a>
               )
            }
         })
      )
   }

   const handleRedirect = (route) => {
      if (localStorage.getItem('token')) {
         navigate(route)
      } else if (route === "/contestlist") {
         navigate(route)
      } else {
         let message = "Login to create new post.";
         if (route === "/myprofile") message = "Login to see your profile.";

         dispatch(
            setShowAlertPopup(
               {
                  show: true,
                  title: "Alert",
                  message: message,
                  showLoginBtn: true
               }
            )
         )
      }
   }

   React.useEffect(() => {
   //  console.log('getMoodBadges effect')
      getMoodBadges();

      // console.log("allmoods",allMoods)
      // console.log("viewMoods",viewMoods)
     //  console.log("selectedLanaguageId",selectedLanaguageId)


   }, [allMoods, viewMoods, selectedMood, selectedLanaguageId])



   const handleGetMorePostOnBottom = () => {
      if (!allPostLoaded) {
         setIsBottomLoading(true);
         getPosts(true);
      }
   }

   React.useEffect(() => {
    //  console.log('get posts effect')
     postOffset=0
      setAllPostLoaded(false)
      getPosts();
   }, [selectedMood, currentLocation, selectedLanaguageId])

//  React.useEffect(() => {
//      console.log('get posts effect')
//       getPosts();
//    }, [selectedMood, currentLocation, selectedLanaguageId])

   // React.useEffect(() => {
   //    console.log('render post effect')

   //    renderPosts();
   // }, [slicePosts])

   return (
      <BottomScrollListener onBottom={handleGetMorePostOnBottom} >
         <div className="main_warp">
            <nav className="navbar sticky-top navbar-light mob-header-bar">
               <div className="w-100" style={{ display: "flex", alignItems: "center" }}>
                  <Link className="navbar-brand header-text me-auto" to="/home">
                     <Brand />
                  </Link>
                  {viewTodaySection ?
                     <div style={{ padding: "7px 10px", marginLeft: "auto", marginRight: 10, color: Colors.purple, display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", }}>
                           <p className="m-0" style={{ fontSize: "1rem", fontWeight: "600" }}>Namaste</p>
                           <img src={Hand} style={{ marginLeft: 5, height: "1.5rem" }} />
                        </div>
                        <p className="m-0" style={{ fontSize: "1rem", fontWeight: "600" }}>{user?.full_name||""}</p>

                     </div>
                     :
                     <>
                        <button
                           data-bs-toggle="modal"
                           data-bs-target="#languageModal"
                           type="button"
                           className="btn btn-secondary-light search-hide" style={{ padding: "7px 10px", marginLeft: "auto", marginRight: 10, backgroundColor: 'orangered', color: "#ffffff" }}>
                           {
                              currentLocation === "Nearme"
                                 ?
                                 <MdMyLocation size={15} style={{ marginRight: 4, marginBottom: 2 }} />
                                 :
                                 currentLocation === "Everywhere"
                                    ?
                                    <i className="fas fa-globe fa-lg me-1" style={{ textTransform: "capitalize", marginRight: 5 }}></i>
                                    :
                                    <MdLocationPin size={15} />
                           }
                           {currentLocation?.length>=20?currentLocation?.split(" ")[0]:currentLocation}
                        </button>
                        <div className="bd-highlight col-1 px-0 align-self-center pe-1">
                           <div className="tabs-btn" data-bs-toggle="modal"
                              data-bs-target="#languageModal" >
                              <img alt='localbol' src="assets/images/NavIcons.svg" />
                           </div>
                        </div>
                     </>
                  }
               </div>
               {/* <div className="mobsearch showsearch" style={{ display: "none" }}>
                  <div className="container-fluid px-0">
                     <form className="w-100">
                        <div className="row">
                           <div className="col-9 pe-0">
                              <div className="input-group flex-nowrap">
                                 <span className="input-group-text">
                                    <i className="fal fa-search"></i>
                                 </span>
                                 <input type="text" className="form-control" placeholder="Search for locations" />
                              </div>
                           </div>
                           <div className="col-3 text-center px-2">
                              <button type="submit" className="btn btn-search">Search</button>
                           </div>
                        </div>
                     </form>
                  </div>
               </div> */}
               <div className="Subnav">
                  <div className="d-flex" style={{ overflow: "scroll", width: "100%" }}>
                     {navItems.map((item) => (
                        <div key={item.id} className={`mx-2 mt-1 ${activeTab === item.id && 'nav-tab'}`} >
                           <p
                              id={`${item.id}-tab`}
                              type="button"
                              role="tab"
                              aria-controls={item.id}
                              aria-selected={activeTab === item.id}
                              onClick={() => {
                                 item.onClick();
                                 item.id === "newPost" ? setActiveTab(activeTab) : setActiveTab(item.id);
                              }}
                           >
                              {item.label}
                           </p>
                        </div>
                     ))}
                  </div>
               </div>
            </nav>
            {viewTodaySection ? <Today onShowCommunity={() => { setActiveTab('community'); setViewTodaySection(false) }} /> :
               <>
                  <div className="container px-0" >
                     <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                           <div className="homeTabs ">
                              <div className="row">
                                 <div className="Tabsnav" style={{ maxWidth:767,marginLeft:15,paddingRight:"16px" }}>
                                    <a className={selectedMood == "All" ? "Tabsnavactive" : ""} onClick={() => { dispatch(setSelectedMood('All')) }} style={{ cursor: "pointer" }}>All</a>
                                    {
                                       moodBadges
                                    }
                                 </div>
                              </div>
                           </div>
                           <div className="w-100" >
                              <Posts loading={isLoading} post={slicePosts} />
                              {/* {posts} */}
                              {
                                 isBottomLoading
                                    ?
                                    <div style={{ width: "100%", padding: "16px 0", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                       <Spinner name='wave' />
                                    </div>
                                    :
                                    null
                              }
                           </div>
                        </div>
                     </div>
                  </div>
                  <LanguageAndMoodModal />
               </>
            }
         </div>
      </BottomScrollListener>
   );
}


 {/* <div className="tab-pane fade" id="business" role="tabpanel" aria-labelledby="business-tab">
                           <div className="container px-0">
                              <div className="Business-main Notifyview">
                                 <div className="Business-img">
                                    <img alt='localbol' src="assets/images/Comingimg.png" className="img-fluid" />
                                 </div>
                                 <h5>Coming Soon...</h5>
                                 <p>
                                    Amet minim mollit non deserunt ullamco est sit aliqua dolor
                                    do amet sint. Velit officia consequat duis enim velit
                                    mollit. Exercitation veniam
                                 </p>
                                 <div className="row justify-content-md-center">
                                    <div className="col-xl-5 col-lg-5 col-md-6">
                                       <div className="d-grid gap-2 my-2">
                                          <button className="btn btn-orange-big Notifybtn" type="button">
                                             Notify me
                                          </button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="Business-main-mobile phoneinput" style={{ display: "none" }}>
                                 <div className="d-flex flex-column bd-highlight mb-3">
                                    <div className="bd-highlight">
                                       <h5>Please share your phone number</h5>
                                       <p>
                                          Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                                       </p>
                                    </div>
                                    <div className="bd-highlight">
                                       <div className="input-group my-3">
                                          <span className="input-group-text" id="basic-addon1"><i className="fal fa-phone-alt"></i></span>
                                          <input type="number" pattern="[0-9]*" inputMode="numeric" maxLength="10" className="form-control" placeholder="" autoComplete="off" />
                                       </div>
                                    </div>
                                    <div className="bd-highlight">
                                       <div className="d-grid gap-2 my-2">
                                          <button className="btn btn-orange-big mebtn" type="button">
                                             Notify me
                                          </button>
                                       </div>
                                    </div>
                                    <div className="bd-highlight">
                                       <div className="d-grid gap-2 my-2">
                                          <button className="btn btn-green-big btn-green" type="button">
                                             <div className="d-flex flex-row bd-highlight">
                                                <div className="bd-highlight flex-fill">
                                                   <div className="btn-green-icons">
                                                      <img alt='localbol' src="assets/images/checkcircle.svg" />
                                                   </div>
                                                </div>
                                                <div className="bd-highlight flex-fill">  We’ll update you once launched !</div>
                                             </div>
                                          </button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div> */}
 {/* <div className="container-fluid ">
                     <div className="d-flex flex-row w-100 row bd-highlight">
                        <div className="bd-highlight col-12 px-0 flex-grow-1 align-self-center">
                           <ul className="nav nav-tabs" id="myTab" role="tablist">
                              <li className="nav-item" role="presentation">
                                 <button onClick={() => setViewTodaySection(true)} className={`nav-link ${viewTodaySection && "active"}`} id="home-tab" data-bs-toggle="tab" data-bs-target="#today" type="button" role="tab" aria-controls="today" aria-selected="true">Today</button>
                              </li>
                              <li className="nav-item" role="presentation">
                                 <button onClick={() => setViewTodaySection(false)} className={`nav-link ${!viewTodaySection && "active"}`} id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Community</button>
                              </li>
                              <li className="nav-item" role="presentation">
                                 <button className="nav-link" id="contests-tab" type="button" role="tab" aria-controls="contests" aria-selected="false" onClick={() => { handleRedirect('/contestlist') }}>Sadhana</button>
                              </li>
                              <li className="nav-item" role="presentation">
                                 <button className="nav-link" id="contests-tab" type="button" role="tab" aria-controls="contests" aria-selected="false" onClick={() => { handleRedirect('/newpost') }}>New Post</button>
                              </li>
                              <li className="nav-item" role="presentation">
                                 <button className="nav-link" id="business-tab" type="button" role="tab" aria-controls="business" aria-selected="false" onClick={() => { handleRedirect('/myprofile') }}>Profile</button>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div> */}
{/* <div className="sharepost">
            <div className="modal fade" id="shareModal" tabIndex="-1" aria-labelledby="shareModalLabel" aria-hidden="true">
               <div className="modal-dialog modal-fullscreen-sm-down modal-fullscreen-sm-downtwo">
                  <div className="modal-content">
                     <div className="modal-body p-0">
                        <div className="d-flex flex-column bd-highlight">
                           <div className="px-4 bd-highlight">
                              <div className="topline"></div>
                           </div>
                           <div className="px-4 bd-highlight">
                              <h6>Share</h6>
                           </div>
                           <div className="px-3 bd-highlight">
                              <div className="input-group mb-3">
                                 <input className="form-control" type="text" value="https://localbol.com/beautifuldoor" aria-label="readonly input example" readOnly />
                                 <span id="liveToastBtn" className="input-group-text">
                                    <i className="far fa-copy fa-lg"></i>
                                 </span>
                              </div>
                           </div>
                           <div className="px-3 pb-3 bd-highlight">
                              <div className="mailbox">
                                 <div className="d-flex flex-row bd-highlight align-items-center">
                                    <div className="p-2 bd-highlight">
                                       <div className="mailboxicons">
                                          <img alt='localbol' src="assets/images/Localbol.svg" width="30" height="30" />
                                       </div>
                                    </div>
                                    <div className="p-2 bd-highlight">
                                       <h5>Check out this beautiful door on Localbol</h5>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="px-3 py-2 bd-highlight border-top">
                              <div className="mailboxsocialmenu">
                                 <div className="mailboxsocialmenu-item">
                                    <div className="mailboxsocialmenu-itemimg">
                                       <img alt='localbol' src="assets/images/Profileimages2.jpg" />
                                    </div>
                                    <div className="mailboxsocialmenu-itemIcons">
                                       <img alt='localbol' src="assets/images/Hangouts.svg" className="img-fluid" />
                                    </div>
                                    <div className="mailboxsocialmenu-itemName">Name</div>
                                 </div>
                                 <div className="mailboxsocialmenu-item">
                                    <div className="mailboxsocialmenu-itemimg">
                                       <img alt='localbol' src="assets/images/Profileimages2.jpg" />
                                    </div>
                                    <div className="mailboxsocialmenu-itemIcons">
                                       <img alt='localbol' src="assets/images/Hangouts.svg" className="img-fluid" />
                                    </div>
                                    <div className="mailboxsocialmenu-itemName">Done</div>
                                 </div>
                                 <div className="mailboxsocialmenu-item">
                                    <div className="mailboxsocialmenu-itemimg">
                                       <img alt='localbol' src="assets/images/Profileimages2.jpg" />
                                    </div>
                                    <div className="mailboxsocialmenu-itemIcons">
                                       <img alt='localbol' src="assets/images/Messages_logo.svg" className="img-fluid" />
                                    </div>
                                    <div className="mailboxsocialmenu-itemName">Massial</div>
                                 </div>
                                 <div className="mailboxsocialmenu-item">
                                    <div className="mailboxsocialmenu-itemimg">
                                       <img alt='localbol' src="assets/images/Profileimages2.jpg" />
                                    </div>
                                    <div className="mailboxsocialmenu-itemIcons">
                                       <img alt='localbol' src="assets/images/WhatsApp2.svg" className="img-fluid" />
                                    </div>
                                    <div className="mailboxsocialmenu-itemName">Massial</div>
                                 </div>
                              </div>
                           </div>
                           <div className="px-3 py-2 bd-highlight border-top">
                              <div className="mailboxsocialmenu">
                                 <div className="mailboxsocialmenu-item">
                                    <div className="mailboxsocialmenu-itemimg">
                                       <img alt='localbol' src="assets/images/Gmail_Icon.svg" />
                                    </div>
                                    <div className="mailboxsocialmenu-itemName">Gmail</div>
                                 </div>
                                 <div className="mailboxsocialmenu-item">
                                    <div className="mailboxsocialmenu-itemimg">
                                       <img alt='localbol' src="assets/images/Messages_logo.svg" />
                                    </div>
                                    <div className="mailboxsocialmenu-itemName">Messages</div>
                                 </div>
                                 <div className="mailboxsocialmenu-item">
                                    <div className="mailboxsocialmenu-itemimg">
                                       <img alt='localbol' src="assets/images/Google_Drive.svg" />
                                    </div>
                                    <div className="mailboxsocialmenu-itemName">Drive</div>
                                 </div>
                                 <div className="mailboxsocialmenu-item">
                                    <div className="mailboxsocialmenu-itemimg">
                                       <img alt='localbol' src="assets/images/WhatsApp2.svg" />
                                    </div>
                                    <div className="mailboxsocialmenu-itemName">WhatsApp</div>
                                 </div>
                              </div>
                           </div>
                           <div className="bd-highlight border-top">
                              <div className="d-flex flex-row bd-highlight">
                                 <div className="px-4 py-2 bd-highlight text-center">
                                    <i data-bs-dismiss="modal" className="far fa-chevron-left"></i>
                                 </div>
                                 <div className="p-2 bd-highlight w-75">
                                    <div className="topline"></div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div> */}