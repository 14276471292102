import React from 'react'
import { useDispatch } from 'react-redux'
import googleLogo from '../assets/google_logo.png'
import { setShowAlertPopup } from '../redux/dataSlice'

const GoogleButton = ({onClick,label}) => {

   
    const buttonStyle = {
      
       display:"flex",
       flex:1,
       fontWeight:'500',
       width:"100%"
      };
    
      const imgStyle = {
        height: '25px',
        width: '25px',
        marginRight: '10px',
      };
  return (
    <button onClick={onClick} className="btn rounded  mt-2 p-2 btn-light d-flex justify-content-center align-items-center " style={buttonStyle}>
      <img src={googleLogo} alt="Google Logo" style={imgStyle} />
      <p style={{ margin: '0px' }}>{label} Google</p>
    </button>
  )
}

export default GoogleButton
