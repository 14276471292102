import React from 'react'
import './styles.css'
import { Colors } from '../../constants/colors';
import Calendar from 'react-calendar';
import { MdArrowForward } from 'react-icons/md';
import moment from 'moment';

const AcceptChallengeWithDateOption = ({ setShowCalender, setParticipation_date, participateDate, handleParticipate,startDate,endDate }) => {
  const onParticipate = () => {
    setShowCalender(false)
    handleParticipate();
  }

  return (

    <div className="overlay">
      <div className='calender-container' >
        <div className='py-2' >
          <h6 className='text-center'>Please Select Your Start Date</h6>
          <p className='m-0 text-center text-dark'> {`Between ${moment(startDate).format("DD MMM, YYYY")}  To ${moment(endDate).format("DD MMM, YYYY")}`}</p>
        </div>
        <Calendar onChange={(date) => setParticipation_date(date)} value={participateDate} minDate={new Date()}  maxDate={new Date(endDate)}/>
        <div className='text-center my-3'>
          <button onClick={onParticipate} className="btn rounded d-flex mx-auto " style={{ backgroundColor: Colors.orange500, alignItems: 'center' }}>
            <p className='m-0 text-white'>LET’S BEGIN</p>
            <MdArrowForward style={{ marginLeft: '6px', color: Colors.white }} />
          </button>
        </div>

      </div>
    </div>

  )
}

export default AcceptChallengeWithDateOption