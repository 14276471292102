export const Colors = {
    primary: '#fe4500',
    orange500: "#FF9B3C",
    secondary: '#fdece8',
    white: '#ffffff',
    grey:"#808080",
    silver:"#C0C0C0	",
    lightGrey: '#f6f6f6',
    lightPink:"#FFD8E1",
    pink500:"#f5a2c3",
    purple500:"#C01D5C",
    black:"#000000",
    purple:"#8815FC",
    
}