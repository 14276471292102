import React from 'react'
import { MdTranslate } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import Brand from '../../../components/Brand'
import CustomButton from '../../../components/CustomButton'
import CustomInput from '../../../components/CustomInput'
import GoogleButton from '../../../components/GoogleButton'
import { Colors } from '../../../constants/colors'
import useCreateAccount from './hook/useCreateAccount'

const CreateAccount = () => {
    const { handleCreateAccount, formData, handleSelectGender, handleInput, handleSelectLanguage, handleGoogleAuth,languages, loading ,setIsAgreeWithTerms,isAgreeWithTerms}=useCreateAccount()
    const navigate=useNavigate()

    return (
        <div className="mb-4 pt-4" style={{
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            paddingTop: 10,
            height: '100vh',
            overflow: 'scroll',
        }} >
            {/* <Brand/> */}
            <div className='p-4 m-4'>
            <img alt="logo" src={require("../../../assets/main_logo.png")} style={{ height: "25vh", width: "100%", zIndex: 2, objectFit: "contain", }} />
            </div>
            <div style={{ backgroundColor: Colors.white, boxShadow: "0 -4px 10px rgba(0,0,0,0.1)", paddingTop: "18px", borderTopRightRadius: 20, borderTopLeftRadius: 20, marginTop: "auto", zIndex: 2 }}>
                <div className='p-4' style={{ display: 'flex', flexDirection: 'column', maxWidth: '600px', margin: '0 auto' }}>
                <GoogleButton label={'Sign up with'} onClick={handleGoogleAuth}/>
             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <hr style={{ flex: 2 }} />
                        <p style={{ color: Colors.grey, flex: 0.5, textAlign: 'center', marginTop: 10 }}>OR</p>
                        <hr style={{ flex: 2 }} />
                    </div>
                    <h6 className='text-center ' style={{ color: Colors.purple500 }} >
                        Create New Account
                    </h6>
                    <div className='d-flex mt-2'>
                        {/* <CustomInput placeholder={"First Name*"} className={"bg-light mr-2"} /> */}
                     <CustomInput type={"text"} name="fullname" placeholder={"Full Name*"}  value={formData.fullname} onChange={handleInput}  className={"bg-light mt-2 "} />
                    </div>
                    <CustomInput type={"text"} name="email" value={formData.email} onChange={handleInput} placeholder={"Email*"} className={"bg-light mt-2 "} />
                    <CustomInput type={"number"} name="phone" value={formData.phone} onChange={handleInput} placeholder={"Mobile"} className={"bg-light mt-2 "} />

                    <CustomInput type={"text"} name="username" value={formData.username} onChange={handleInput} placeholder={"Username*"} className={"bg-light mt-2 "} />
                    <CustomInput type={"text"} name="referral_code" value={formData.referral_code} onChange={handleInput} placeholder={"Referral code (optional)"} className={"bg-light mt-2 "} />

                    <CustomInput type={"password"} name="password" value={formData.password} onChange={handleInput} placeholder={"Password*"} className={"bg-light mt-2 "} />

                    <h6 className='mt-4' >
                        Gender*
                    </h6>
                    <div className='d-flex ' >
                        <CustomButton onClick={() => handleSelectGender("male")} label={"Male"} className={`w-100 mr-2 bg-light ${formData.gender === 'male' ? "selected-gender" : ""}`} customStyle={{ color: formData.gender === 'male' ? Colors.purple500 : Colors.black }} />
                        <CustomButton onClick={() => handleSelectGender("female")} label={"Female"} className={`w-100 mr-2 bg-light ${formData.gender === 'female' ? "selected-gender" : ""}`} customStyle={{ color: formData.gender === 'female' ? Colors.purple500 : Colors.black }} />
                        <CustomButton onClick={() => handleSelectGender("other")} label={"Other"} className={`w-100 bg-light ${formData.gender === 'other' ? "selected-gender" : ""}`} customStyle={{ color: formData.gender === 'other' ? Colors.purple500 : Colors.black }} />
                    </div>
                    <div className='my-4' style={{ display: "flex", alignItems: "center", borderRadius: 8, backgroundColor: Colors.lightGrey, border: "none", padding: "10px 8px", fontFamily: "Open Sans", width: "100%" }}>
                        <MdTranslate />
                        {
                            languages === null || languages?.length == 0
                                ?
                                <span style={{ fontSize: 12, marginLeft: 12 }}>English (default)</span>
                                :
                                <select onChange={(e) => { handleSelectLanguage(e) }} style={{ flex: 1, paddingLeft: 20, backgroundColor: "transparent", border: "none", alignItems:'center'}}>
                                    {
                                        languages?.map((lang, index) => {
                                            return (
                                                <option key={index} value={lang.id}>{lang?.language_name}</option>
                                            )
                                        })
                                    }
                                </select>

                        }
                    </div>
                 
                    <label style={{ whiteSpace: 'nowrap',fontSize:14,display:'flex',alignItems:"center" }}>
                        <input
                            id="specifyColorRadioBtn"
                            type="checkbox"
                           checked={isAgreeWithTerms}
                           onChange={(e) => setIsAgreeWithTerms(e.target.checked)}
                            style={{ marginRight: "5px" }}
                        />
                       I agree to receive communications via WhatsApp
                    </label>
                    <CustomButton onClick={handleCreateAccount} disabled={loading?true:false} label={"Create New Account"} className={"btn-block"} color={Colors.purple500} />
                       {/* <h6 className='text-center mt-3'>Forgot Password?</h6> */}
                  


                       <p className='text-center mt-3' style={{ right: 0, left: 0, fontSize:"0.8rem"}}>
                        By using Bhagwan.Live, You agree to our <Link to="/terms-and-condition" style={{ color: Colors.purple500,fontWeight:'500' }}>Terms & conditions</Link>,and <Link to="/privacy-policy" style={{ color: Colors.purple500,fontWeight:'500' }}>Privacy policy</Link>.
                    </p>
                    <p className='text-center mt-3'>
                    Already have an account?<Link to="/" style={{ color: Colors.purple500,fontWeight:'500' }}>Log In</Link>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default CreateAccount
