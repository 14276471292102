import React from 'react';
import {useNavigate } from 'react-router-dom';
import CustomButton from '../components/CustomButton';

const NotFound = () => {
  const navigate=useNavigate()

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  };

  return (
    <div className="container" style={containerStyle}>
      <h2 className="text-dark">404 - Not Found</h2>
      <p className="text-muted">Sorry, the page you are looking for does not exist.</p>
      <CustomButton label={"Go Back to Home"} onClick={()=>navigate("/")} />
     
    </div>
  );
};

export default NotFound;
