import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";
import { SUBDOMAIN_NAME } from "../../constants/subdomain";
import { setShowAlertPopup } from "../../redux/dataSlice";

export default function RegisterScreen() {
  let location = useLocation();
  let [fullname, setFullname] = React.useState(location?.state?.full_name ? location?.state?.full_name : "");
  let [username, setUsername] = React.useState("");
  let [referralCode, setReferralCode] = React.useState("");
  let navigate = useNavigate();
  const dispatch = useDispatch()

  const handleInput = (e) => {
    if (e.target.name == "full_name") {
      setFullname(e.target.value);
    } else if (e.target.name == "username") {
      setUsername(e.target.value);
    } else if (e.target.name == "referral_code") {
      setReferralCode(e.target.value);
    }
  };

  function showAlertPopup(message) {
    dispatch(
      setShowAlertPopup({
        show: true,
        title: 'Alert',
        message,
      })
    );
  }


  const handleRegister = () => {

    if (!location.state?.email || !location.state?.email_verified || !location.state?.picture || !location.state?.google_id) {
      showAlertPopup('Something went wrong')
      return;
    }
    if (!fullname) {
      showAlertPopup("Please enter fullname")
      return;

    }
    if (!username) {
      showAlertPopup("Please enter username")
      return;

    }


    let data = {
      email: location.state?.email,
      full_name: fullname,
      username: username,
      referral_code: referralCode,
      email_verified: location.state?.email_verified,
      picture: location.state?.picture,
      google_id: location.state?.google_id,
    };

    localStorage.setItem('register_user_data', JSON.stringify(data));
    navigate('/languageSelectionScreen');
    // customRequest('user/checkusername', { username: username }).then((res) => {
    //   //console.log(res);
    //   if (res.status === "fail") {
    //     setUsernameCheck(res.message);
    //   } else {
    //     let data = {
    //     //  phone: location.state.phone,
    //       full_name: fullname,
    //       username: username,
    //       referral_code: referralCode,
    //       email_verified:location.state?.email_verified,
    //       picture:location.state?.picture,
    //       google_id:location.state?.google_id,
    //     };

    //     localStorage.setItem('register_user_data', JSON.stringify(data));
    //     navigate('/languageSelectionScreen');
    //   }
    // })


  };

  useEffect(() => {
    setFullname(location?.state?.full_name)

  }, [])


  return (
    <div style={{ height: "100vh", display: "flex", flexDirection: "column", zIndex: 1 }}>
      <img alt="logo" src={require("../../assets/main_logo.png")} style={{ height: "40vh", width: "100%", zIndex: 2, objectFit: "contain" }} />

      {/* <img src={require("../../assets/logo.png")} style={{ position: "absolute", top: "20%", left: "50%", transform: "translateX(-50%)", zIndex: 2, height: "36px" }} /> */}
      <div style={{ height: "56%", backgroundColor: "white", boxShadow: "0 -4px 10px rgba(0,0,0,0.1)", paddingTop: "18px", borderTopRightRadius: 20, borderTopLeftRadius: 20, marginTop: "auto", zIndex: 2 }}>
      <div className="px-3 py-4" style={{ display: 'flex', flexDirection: 'column', maxWidth: '600px', margin: '0 auto' }}>
          <h4 style={{ fontSize: 19, fontWeight: "700", lineHeight: 1 }}><span style={{ color: "orangered" }}>Setup</span> Your Account</h4>
          <span style={{ fontSize: 12, fontFamily: "Open Sans", color: "gray" }}>Create your username to help your friends find you on the {SUBDOMAIN_NAME}</span>

          <div className="mt-4">
            <CustomInput value={fullname}
              type="text"
              name="full_name"
              placeholder="Full Name*"
              className={"bg-light mt-2 "}
              onChange={handleInput} />

            <CustomInput
              value={username}
              className={"bg-light mt-2 "}
              type="text"
              name="username"
              placeholder="Username*"
              onChange={handleInput} />
            <CustomInput
              className={"bg-light mt-2 "}
              value={referralCode}
              type="text"
              name="referral_code"
              placeholder="Referral Code (optional)"
              onChange={handleInput} />

          </div>
          {/* {
            usernameCheck === null
              ?
              null
              :
              <span style={{ fontFamily: "Open Sans", fontSize: 12, color: "red" }}>{usernameCheck}</span>
          }
         */}

          <CustomButton className={"btn-block mt-4"} label={"Continue"} onClick={handleRegister} />


        </div>
      </div>
    </div>
  );
}
