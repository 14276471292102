import React from 'react'
import Loader from '../../../components/Loader'
import { PostCard } from '../../../components/postCard'

const Posts = ({ post, loading }) => {


    if (loading) {
        return (<Loader />)
    }


    if (post?.length === 0) {
        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", height: "70vh", flex: 1 }}>
                <img src={require("../../../assets/empty_post.png")} style={{ height: 240, width: 240, objectFit: "contain" }} />
                <p style={{ fontFamily: "Open Sans", fontSize: 15, textAlign: "center" }}>No any post.</p>
            </div>
        )
    }

    return ( Array.isArray(post) && post?.map(post => (
          <PostCard key={post?.id} post={post} />
    ))
    )
}

export default Posts