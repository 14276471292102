import React from "react";
import { MdArrowBack, MdChevronLeft } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { defaultUserImageLink } from "../functions/link";
import { customRequest } from "../functions/request";


export default function RespectorAndRespectingScreen() {
    const navigate = useNavigate();
    const param = useParams();
    let [respects, setRespects] = React.useState(null);

    const getData = () => {
        customRequest('user/respect-user-details', { type: param.type }).then((res) => {
            if(res.users.length > 0){
                setRespects(
                    res.users.map((item, index) => {
                        return (
                            <div key={index} style={{ padding: "6px 12px", display: "flex",alignItems:"center" ,borderBottom:"1px solid rgba(0,0,0,0.1)"}}>
                                <img src={defaultUserImageLink} style={{ height: 40, width: 40, borderRadius: 40 }} />
                                <div style={{ marginLeft: 10 }}>
                                    <span style={{ display: "flex" ,fontWeight:"500",fontSize:16,lineHeight:1}}>{item.full_name}</span>
                                    <span style={{ display: "flex" ,fontSize:14,lineHeight:1,marginTop:3}}>@{item.username}</span>
                                </div>
                            </div>
                        )
                    })
                )
            }else{
                setRespects(
                    <div style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",height:"calc(100vh - 64px)"}}>
                        <img src={require("../assets/empty_post.png")} style={{height:160,width:240,objectFit:"contain"}}/>
                        <span>Data not available.</span>
                    </div>
                )
            }
        })
    }

    React.useEffect(() => {
        getData();
    }, [])

    return (
        <div>
            <div style={{ height: 48, width: "100%", boxShadow: "0 4px 4px rgba(0,0,0,0.1)", marginBottom: 1, display: "flex", alignItems: "center", padding: "0 16px" }}>
                <MdChevronLeft size={24} onClick={() => { navigate(-1) }} />
                <span style={{ fontFamily: "Open Sans", fontSize: 16, marginLeft: 10, lineHeight: "1px", textTransform: "capitalize" }}>{param.type}</span>
            </div>
            {
                respects
            }
        </div>
    )
}