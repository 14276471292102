import React from 'react'
import { MdArrowBack, MdArrowBackIos } from 'react-icons/md'
import CustomButton from '../../components/CustomButton'
import CustomInput from '../../components/CustomInput'
import { Colors } from '../../constants/colors'

const ForgotPassword = () => {
    return (
        <div className='p-4'>
            <div className='px-2'>

                <button className=' btn rounded-circle shadow '><MdArrowBackIos /></button>
            </div>
            <div className='mt-4'>
                <h2 >
                    Enter the Email
                </h2>

                <h2> number you used to sign up</h2>
                <CustomInput placeholder={"Email address*"} className={"bg-light mt-4 "}  />
                <CustomButton label={"Send email"} className={"btn-block mt-4"} color={Colors.orange500} />
            </div>
            <h6 className='mt-4 text-center text-secondary'> We’ll send you a password reset OTP</h6>

            <h6 className='mt-4 text-center' style={{ position: 'absolute', bottom: '10px', right: 0, left: 0,fontSize:12 }}> By using Bhagwan.Live, You agree to our Terms of use</h6>

        </div>
    )
}

export default ForgotPassword
