import React, { useEffect, useRef, useState } from 'react';
import './style.css'; // Import your CSS file

const LazyLoadImage = ({ src, alt, className, style, ...props }) => {
    const imgRef = useRef();
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // Load the high-resolution image when it comes into the viewport
                    const image = new Image();
                    image.src = src;
                    image.onload = () => {
                        // Replace the original img element with the new Image object
                        imgRef.current.src = image.src;
                        observer.unobserve(imgRef.current);
                    };
                }
            });
        });

        // Start observing the image element
        observer.observe(imgRef.current);

        // Cleanup the observer when the component unmounts
        return () => {
            observer.disconnect();
        };
    }, [src]);

    return (
        <img
            ref={imgRef}
            alt={alt}
            loading="lazy"
            className={`${className} ${imageLoaded ? 'loaded' : 'loading'}`}
            onLoad={() => setImageLoaded(true)} // Set imageLoaded to true on image load
            style={{height: "400px", objectFit: "cover", width: "100%",...style}}
            {...props}
        />
    );
};

export default LazyLoadImage;
