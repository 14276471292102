import React from 'react';
import { MdArrowBack, MdLocationPin, MdClose, MdAddLocation } from "react-icons/md";
import useLocationSearch from './hook/useLocationSearch';
import SpinnerOverlay from '../../components/OverlayLoader';
import Spinner from 'react-spinkit';
import { getLocationDetails } from '../../functions/helper';


export default function LocationSearchScreen() {
    const { result, navigateBack, deleteSavedLocation, handleOnInputSearch, handleSelectLocation, placePredictions, searchText, isLoading, searchBy, isPlacePredictionsLoading, savedLocations, isDeleting, setSearchBy, searchLocation, onInputPlacePrediction, isUpdating, setSearchText ,setIsLoading} = useLocationSearch()


    return (
        <div className='main_warp' style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ height: 48, width: "100%", boxShadow: "0 4px 4px rgba(0,0,0,0.1)", marginBottom: 1, display: "flex", alignItems: "center", padding: "0 16px" }}>
                <MdArrowBack size={24} onClick={navigateBack} />
                <h4 style={{ fontFamily: "Open Sans", fontSize: 18, marginLeft: 10, lineHeight: "1px", marginTop: 5 }}>Add or Update Locations</h4>
            </div>
            <div style={{ display: "flex", alignItems: "center", margin: "6px 12px", justifyContent: "space-between" }}>
                <div style={{ padding: "6px 12px", backgroundColor: "rgba(0,0,0,0.1)", borderRadius: 5, fontSize: 10, cursor: "pointer", border: searchBy === "location" ? "1px solid orangered" : "none" }} onClick={() => { setSearchBy('location'); setSearchText("") }}>
                    By Location Name
                </div>
                <div style={{ padding: "6px 12px", backgroundColor: "rgba(0,0,0,0.1)", borderRadius: 5, fontSize: 10, cursor: "pointer", border: searchBy === "currentlocation" ? "1px solid orangered" : "none" }} onClick={() => { setSearchBy('currentlocation'); searchLocation('current');setIsLoading(true) }}>
                    By Current Location
                </div>
                <div style={{ padding: "6px 12px", backgroundColor: "rgba(0,0,0,0.1)", borderRadius: 5, fontSize: 10, cursor: "pointer", border: searchBy === "pincode" ? "1px solid orangered" : "none" }} onClick={() => { setSearchBy('pincode'); setSearchText("") }}>
                    By Pincode
                </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "6px 12px" }}>

              {searchBy !== "currentlocation" && <div style={{ padding: "6px 12px", backgroundColor: "rgba(0,0,0,0.1)", borderRadius: 5, flex: 1 }}>
                    {/* <i className="fas fa-search fa-lg me-1" style={{ color: '#605e5c', textTransform: "capitalize", marginRight: 8 }}></i> */}
                    {
                        searchBy === "location" ?
                            <input
                                value={searchText}
                                style={{ fontFamily: "Open Sans", fontSize: 15, border: "none", width: "84%", backgroundColor: "transparent" }}
                                placeholder="Enter location name"
                                onInput={onInputPlacePrediction}
                                loading={isPlacePredictionsLoading}
                            /> :
                            <input value={searchText} maxLength={searchBy === "pincode" ? 6 : 60} style={{ fontFamily: "Open Sans", fontSize: 15, border: "none", width: "84%", backgroundColor: "transparent" }} placeholder={searchBy === "pincode" ? "Enter pincode" : "Enter location"} onInput={(e) => { handleOnInputSearch(e.target.value) }} />}
                </div>}
                {/* <div style={{ cursor: "pointer", border: "1px solid orangered", borderRadius: 60, padding: "8px 8px", marginRight: 8, marginLeft: 10, display: "flex", alignItems: "center", justifyContent: "center" }} >
                    <i className="fas fa-location fa-lg me-1" style={{ color: 'orangered', textTransform: "capitalize" }}></i>
                    <MdMyLocation color='orangered' />
                </div> */}
            </div>

            <div style={{ display: "flex", flexDirection: "column", height: "calc(50vh)", overflowY: "auto" }}>
                {(isLoading || isPlacePredictionsLoading)
                    ?
                    <div style={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Spinner name="circle" />
                    </div>
                    :
                    searchBy === "location" ?
                        placePredictions?.map((data, index) => {
                            const { place: location, city, country, pinCode: pincode, state } = getLocationDetails(data?.terms, 'prediction')
                            return (
                                <div key={index} style={{ padding: "12px 12px", width: "100vw", display: "flex", alignItems: "center", borderBottom: "1px solid rgba(0,0,0,0.05)", cursor: 'pointer' }} onClick={() => { handleSelectLocation(location, { location, pincode, city, state, country }) }}>
                                    <MdLocationPin size={20} />
                                    <span style={{ color: "black", fontSize: 15, marginLeft: 5 }}> {data.structured_formatting?.main_text || ""} {data.structured_formatting?.secondary_text || ""}</span>
                                </div>
                            )
                        })
                        : result}

            </div>
            <div style={{ display: "flex", flexDirection: "column",overflowY: "auto", padding: 12, borderTop: "1px solid rgba(0,0,0,0.1)" }}>
                <h4 style={{ fontSize: 16, fontWeight: "600" }}>Saved Location</h4>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {
                        savedLocations?.filter(location => location !== 'undefined' && location !== 'null' && location !== "")?.map((location, index) => {
                            return (
                                <span key={index} style={{ padding: "3px 6px", backgroundColor: "rgba(0,0,0,0.3)", borderRadius: 60, fontSize: 14, margin: 4 }}>
                                    {location}
                                    <MdClose style={{ cursor: "pointer", marginBottom: 2, marginLeft: 4 }} onClick={() => { !isDeleting && deleteSavedLocation(location) }} />
                                </span>
                            )
                        })
                    }
                </div>
            </div>
            {(isDeleting || isUpdating) && <SpinnerOverlay />}
        </div>
    )
}