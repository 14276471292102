import React from 'react'
import { MdArrowBack, MdArrowBackIos } from 'react-icons/md'
import CustomButton from '../../components/CustomButton'
import CustomInput from '../../components/CustomInput'
import { Colors } from '../../constants/colors'

const ChangePassword = () => {
    return (
        <div className='p-4'>
            <div className='px-2'>

                <button className=' btn rounded-circle shadow '><MdArrowBackIos /></button>
            </div>
            <div className='mt-4'>
                <h2 >
                    Create a new password
                </h2>


                <CustomInput placeholder={"Create New Password"} className={"bg-light mt-4 "} />
                <CustomInput placeholder={"Re enter New Password"} className={"bg-light mt-3"} />
                <h6 className='mt-4 text-center'> Enter the OTP you have received via Email</h6>

                <div className='d-flex justify-content-between mt-4'>
                    <CustomInput className={"bg-light pb-4"} />
                    <CustomInput className={"bg-light pb-4"} />
                    <CustomInput className={"bg-light pb-4 "} />
                    <CustomInput className={"bg-light pb-4 "} />

                </div>
                <h6 className='mt-2' style={{ float: 'right',color:Colors.purple500 }}>
                    Resend
                </h6>


                <CustomButton label={"Change password"} className={"btn-block mt-4"} color={Colors.orange500} />
            </div>

            <h6 className='mt-4 text-center' style={{ position: 'absolute', bottom: '10px', right: 0, left: 0,fontSize:12 }}> By using Bhagwan.Live, You agree to our Terms of use</h6>

        </div>
    )
}

export default ChangePassword
