import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getLoggedUser } from '../../../../functions/helper'
import { customRequest, customRequestGET, } from '../../../../functions/request'
import { setShowAlertPopup } from '../../../../redux/dataSlice'

const slidesArray = []

const useToday = () => {
    const [showSadhana, setShowSadhana] = useState(true)
    const [showCommunity, setShowCommunity] = useState(true)
    const [collection, setCollection] = useState([])
    const [selectedImage, setSelectedImage] = useState({})
    const [slideImages, setSlideImages] = useState([])
    const [showMandir, setShowMandir] = useState(true)
    const [loading, setLoading] = useState(true)

    const dispatch = useDispatch()
    const navigate = useNavigate()


    function storeSlideImageInCache(category_id, data) {
        const isAlreadyAdded = slidesArray.some(elm => elm?.category_id === category_id)
        if (!isAlreadyAdded) slidesArray.push({ category_id, data })
    }

    //   console.log(slidesArray)

    useEffect(() => {
        customRequestGET("today/allCategories")
            .then(data => {
                setLoading(false)
                if (Array.isArray(data?.today_categories)) {
                    const todayData = data?.today_categories?.find((elm, index) => {
                        if (moment().format("dddd").toLowerCase() === elm?.default_day?.toLowerCase()) {
                            return index
                        }
                    })
                    if (data?.today_categories)
                        setCollection(data?.today_categories)
                    if (todayData?.id) {
                        setSelectedImage(todayData)
                    } else {
                        setSelectedImage(data?.today_categories[0])
                    }
                }
            })
            .catch(e =>{
                setLoading(false)
            })

    }, [])

    useEffect(() => {
        const reqBody = {
            "category_id": selectedImage?.id
        }
        const alreadyAvilableInCache = slidesArray.find(elm => elm?.category_id === selectedImage?.id)
        //  console.log("alreadyAvilableInCache", alreadyAvilableInCache)
        if (alreadyAvilableInCache) {
            setSlideImages(alreadyAvilableInCache?.data)
            return;
        }
        customRequest("today/allCategoryImages", reqBody)
            .then(data => {
                if (Array.isArray(data?.today_category_data)) {
                    setSlideImages(data?.today_category_data)
                    storeSlideImageInCache(reqBody.category_id, data?.today_category_data)

                }
            })
            .catch(e => console.log(e))

    }, [selectedImage])

    const handleAllSadhanas = () => {
        navigate('/contestlist')
    }
    const handleCreatePost = () => {
        if (!getLoggedUser()) {
            dispatch(
                setShowAlertPopup(
                    {
                        show: true,
                        title: "Alert",
                        message: "Login to create new post",
                        showLoginBtn: true
                    }
                )
            )
            return;
        }
        navigate('/newpost')
    }
    const handleMySadhana = () => {
        navigate('/contestlist', { state: { redirectToMyChallenge: true } })
    }
    return { showCommunity, setShowCommunity, handleAllSadhanas, handleMySadhana, slideImages, collection, setSelectedImage, selectedImage, showMandir, setShowMandir, showSadhana, setShowSadhana, handleCreatePost ,loading}
}

export default useToday;