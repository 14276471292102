import React from 'react';
import { Colors } from '../constants/colors';

const SpinnerOverlay = () => {
  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#000000aa', // Set alpha to 0 for transparency
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
  };

  return (
    <div style={overlayStyle}>
      <div className="spinner-border" role="status" style={{ color: Colors.orange500, width: '3rem', height: '3rem' }}></div>
    </div>
  );
};

export default SpinnerOverlay;
