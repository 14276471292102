import React from 'react';
import { useNavigate } from 'react-router-dom';
import { customRequest } from '../functions/request';

export default function RespectInfo(props) {
    const [respects, setRespects] = React.useState(null);
    const navigate = useNavigate();

    const getRespectInfo = () => {
        customRequest('user/get-my-respect-info', { user_id: props.userid }).then((res) => {
            setRespects(res);
        })
    }

    React.useEffect(() => {
        getRespectInfo();
    }, [])

    return (
        <div>
            <hr style={{ margin: "0 12px" }} />
            <div style={{ padding: "12px 16px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => { navigate('/respect/respecting') }}>
                    <span style={{ color: "orangered", fontWeight: "bold", fontSize: 18, marginRight: 4 }}>{respects === null ? 0 : respects?.respecting||0}</span>
                    <span style={{ color: "gray", fontSize: 16 }}>Respecting</span>
                </div>
                <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => { navigate('/respect/respectors') }}>
                    <span style={{ color: "orangered", fontWeight: "bold", fontSize: 18, marginRight: 4 }}>{respects === null ? 0 : respects?.respectors||0}</span>
                    <span style={{ color: "gray", fontSize: 16 }}>Respectors</span>
                </div>
            </div>
        </div>
    )
}