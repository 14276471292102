import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setShowAlertPopup } from '../redux/dataSlice';

export default function AlertPopup() {
    const alertdata = useSelector(state => state.data.alertPopup);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleClosePopup = () => {
        dispatch(
            setShowAlertPopup({
                show: false,
                message: ""
            })
        )
    }

    const handleLogin = () => {
        dispatch(
            setShowAlertPopup({
                show: false,
                message: ""
            })
        )
        navigate('/');
    }

    const handleRegister = () => {
        dispatch(
            setShowAlertPopup({
                show: false,
                message: ""
            })
        )
        navigate("/create-account")
    }

    return (
        <div style={{ display: alertdata.show ? "block" : "none", width: "100vw", backgroundColor: "rgba(0,0,0,0.3)", position: "fixed", top: 0, left: 0, right: 0, bottom: 0, zIndex: 10000 }}>
            <div style={{ padding: 12, borderRadius: 10, boxShadow: "0 4px 4px rgba(0,0,0,0.1)", backgroundColor: "white", position: "absolute", top: 20, left: "50%", transform: "translateX(-50%)", width: "calc(100vw - 20px)", display: "flex", flexDirection: "column" }}>
                <span style={{ fontWeight: "600" }}>{alertdata.title}</span>
                <div style={{ height: 1, width: "100%", backgroundColor: "rgba(0,0,0,0.1)", margin: "6px 0" }}></div>
                <span style={{ fontSize: 15 }}>{alertdata.message}</span>
                <div style={{ display: "flex", marginLeft: "auto" }}>
                    {
                        alertdata.showLoginBtn
                            ?
                            <button onClick={() => { handleLogin() }} style={{ display: "block", border: "none", backgroundColor: "orangered", borderRadius: 10, padding: "4px 10px", fontSize: 15, color: "white", marginTop: 10 }}>Login</button>
                            :
                            null
                    }
                     {
                        alertdata.registerBtn
                            ?
                            <button onClick={handleRegister} style={{ display: "block", border: "none", backgroundColor: "orangered", borderRadius: 10, padding: "4px 10px", fontSize: 15, color: "white", marginTop: 10 }}>Create Account</button>
                            :
                            null
                    }
                    <button onClick={() => { handleClosePopup() }} style={{ display: "block", border: "none", backgroundColor: "orangered", borderRadius: 10, padding: "4px 10px", fontSize: 15, color: "white", marginTop: 10, marginLeft: 8 }}>OK</button>
                </div>
            </div>
        </div>
    )
}