import React from 'react'
import { Link } from 'react-router-dom'
import Loader from '../../components/Loader'
import useVerify from './hook/useVerify'

function Verify() {
  const {loading,isVerified} = useVerify()


  if (!loading) {
    return<Loader/>
  }

  return (
    <div className="main_warp d-flex justify-content-center align-items-center" style={{height:"100vh",width:"100vw"}}>
    <h5 >successfully verified</h5>
    <div>
    <h6>Press <Link to="/"> </Link> to login</h6>
    </div>
    </div>
    )
}

export default Verify