import React from 'react';
import { MdClose, MdInfoOutline } from 'react-icons/md';
import { useDispatch, useSelector } from "react-redux";
import { setShowHidePostStatusPopup } from '../redux/dataSlice';

export default function PostStatusPopup() {
    const dispatch = useDispatch();
    const showHidePostStatusPopup = useSelector(state => state.data.showPostStatusPopup);

    React.useEffect(()=>{
        if(showHidePostStatusPopup){
            document.body.style.height = "100vh";
            document.body.style.overflowY = "hidden";
        }else{
            document.body.style.overflowY = "auto";
        }
    },[showHidePostStatusPopup])

    return (
        <div style={{display: showHidePostStatusPopup ? "block" : "none", position: 'fixed', top: "0", left: "0", right: 0, bottom: 0, zIndex: 10000,backgroundColor:"rgba(0,0,0,0.2)"}}>
            <div style={{  position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)", backgroundColor: "white", borderRadius: "10px", padding: "10px", height: "auto", width: "calc(100vw - 40px)", boxShadow: "0 4px 4px rgba(0,0,0,0.1)" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "4px 0" }}>
                    <span style={{ fontSize: 16, fontWeight: "600", fontFamily: "Open Sans", lineHeight: 1, paddingBottom: 0 }}>Post Status</span>
                    <MdClose style={{ cursor: "pointer" }} onClick={() => { dispatch(setShowHidePostStatusPopup(false)) }} />
                </div>
                <span style={{ width: "100%", height: "1px", backgroundColor: "rgba(0,0,0,0.1)", margin: "6px 0", display: "block" }} />
                <div style={{ display: "flex", alignItems: "center",  padding: "4px 0" }}>
                    <MdInfoOutline style={{ background: "green", borderRadius: 60 }} size={36} />
                    <span style={{ fontSize: 15, marginLeft:10,maxWidth: "80%" }}>Green Post Status meaning, majority of the people are giving Upvote to this post</span>
                </div>
                <div style={{ display: "flex", alignItems: "center",  padding: "4px 0" }}>
                    <MdInfoOutline style={{ background: "red", borderRadius: 60 }} size={36} />
                    <span style={{ fontSize: 15, marginLeft:10,maxWidth: "80%" }}>Red Post Status meaning, majority of the people are giving Downvote to this post</span>
                </div>
                <div style={{ display: "flex", alignItems: "center",  padding: "4px 0" }}>
                    <MdInfoOutline style={{ background: "yellow", borderRadius: 60 }} size={36} />
                    <span style={{ fontSize: 15, marginLeft:10,maxWidth: "80%" }}>Yellow Post Status meaning, few people voted(Upvote or Downvote) for this post</span>
                </div>
                <div style={{ display: "flex", alignItems: "center",  padding: "4px 0" }}>
                    <MdInfoOutline style={{ background: "gray", borderRadius: 60 }} size={36} />
                    <span style={{ fontSize: 15, marginLeft:10,maxWidth: "80%" }}>Gray Post Status meaning, no one has voted (Upvote or Downvote) for this post</span>
                </div>
            </div>

        </div>
    )
}