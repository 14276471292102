import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import './index.css';



const SwipeComponent = ({ slides }) => {

   
    return (
        <Swiper
            direction={'vertical'}
            centeredSlides={true}
            initialSlide={0} // Set the starting index (0 for the first slide)
            speed={0.8}
            className="mySwiper"
        >
            {slides?.map((elm) => (
                <SwiperSlide key={elm?.id}>
                    <img
                        loading='lazy'
                        src={elm?.file_name}
                        alt={`Image ${elm?.file_name}`}
                        className='image-fluid mx-1'
                    />
                </SwiperSlide>
            ))}
        </Swiper>
    )
}

export default SwipeComponent


// <SwiperSlide >
// <img
//     src={Gif2}
//   //  alt={`Image ${elm?.id}`}
//     className='image-fluid mx-1'
//  //   style={{resizeMode:"cover"}}
// />
// </SwiperSlide>
// <SwiperSlide >
// <img
//     src={Gif3}
//   //  alt={`Image ${elm?.id}`}
//     className='image-fluid mx-1'
//  //   style={{resizeMode:"cover"}}
// />
// </SwiperSlide>
// <SwiperSlide >
// <img
//     src={Gif4}
//   //  alt={`Image ${elm?.id}`}
//     className='image-fluid mx-1'
//  //   style={{resizeMode:"cover"}}
// />
// </SwiperSlide>
// <SwiperSlide >
// <img
//     src={Gif}
//   //  alt={`Image ${elm?.id}`}
//     className='image-fluid mx-1'
//  //   style={{resizeMode:"cover"}}
// />
// </SwiperSlide>
// <SwiperSlide >
// <img
//     src={G10}
//   //  alt={`Image ${elm?.id}`}
//     className='image-fluid mx-1'
//    // style={{resizeMode:"cover"}}
// />
// </SwiperSlide>
// <SwiperSlide >
// <img
//     src={G7}
//   //  alt={`Image ${elm?.id}`}
//     className='image-fluid mx-1'
// />
// </SwiperSlide>  <SwiperSlide >
// <img
//     src={G8}
//    // alt={`Image ${elm?.id}`}
//     className='image-fluid mx-1'
// />
// </SwiperSlide>