import axios from "axios";
import { SUBDOMAIN_ID } from "../constants/subdomain";
import {
    rootlink
} from "./link";


// async function customRequest(path, body = {}, method = 'POST') {
//     const token = localStorage.getItem('token');


//     const headers = {
//         'Content-Type': 'application/json',
//         'accept': 'application/json',
//         'subdomain': SUBDOMAIN_ID
//     };
//     if (token) {
//         headers.authorization = `Bearer ${token}`;
//     }

//     const timeoutPromise = new Promise((_, reject) => {
//         setTimeout(() => {
//             reject(new Error('Request timed out please try again'));
//         }, 20000);
//     });
//     try {
//         const response = await Promise.race([
//             fetch(`${rootlink}/${path}`, {
//                 method: method,
//                 body: JSON.stringify({ ...body }),
//                 headers: headers,
//             }),
//             timeoutPromise, // the timeout promise to the race
//         ]);


//         // if (!response.ok) {
//         //     return await response.json()
//         // }

//         return await response.json();
//     } catch (error) {
//         throw new Error(error.message);
//     }
// }

async function customRequest(path, body = {}, method = 'POST') {
    const token = localStorage.getItem('token');

    const headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'subdomain': SUBDOMAIN_ID
    };

    if (token) {
        headers.authorization = `Bearer ${token}`;
    }

    try {
        const response = await axios({
            method: method,
            url: `${rootlink}/${path}`,
            data: { ...body },
            headers: headers,
            timeout: 20000, // Set timeout directly using Axios
        });

        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
}

function customRequestGET(path) {
    const token = localStorage.getItem('token');

    return fetch(`${rootlink}/${path}`, {
        method: "GET",
        headers: {
            'Content-Type': "application/json",
            'accept': 'application/json',
            'authorization': "Bearer " + token,
            'subdomain': SUBDOMAIN_ID

        }
    })
        .then(res => {
            return res.json()
        })
        .then((json) => {
            return json
        });
}

function customRequestGETBare(path) {
    const token = localStorage.getItem('token');

    return fetch(path, {
        method: "GET",

        // headers: {
        //     'Content-Type': "application/json",
        //     'accept': 'application/json',
        // }
    })
        .then(res => {
            return res.json()
        })
        .then((json) => {
            return json
        });
}


function customMultipartRequest(path, body = {}, method = 'POST') {
    const token = localStorage.getItem('token');

    return fetch(`${rootlink}/${path}`, {
        method: method,
        body: body,
        headers: {
            // 'Content-Type': "multipart/form-data",
            'accept': 'application/json',
            'authorization': "Bearer " + token,
            'subdomain': SUBDOMAIN_ID

        }
    })
        .then(res => {
            return res.json()
        })
        .then((json) => {
            return json
        });
}


const getApiService = async (path,reqBody) => {
    const token = localStorage.getItem('token');
    console.log(reqBody)
    axios({
        method: 'get',
        url: `${rootlink}/${path}`,
        data: reqBody, // Include data in the request body
        headers: {
            'accept': 'application/json',
            'authorization': "Bearer " + token,
            'subdomain': SUBDOMAIN_ID

        }
    })
        .then(response => {
        return response.data;
        })
        .catch(error => {
            console.error('Error:', error);
        });
}

export {
    customRequest,
    customMultipartRequest,
    customRequestGET,
    customRequestGETBare,
    getApiService
}