import React from 'react';

export default function AuthoritiesScreen(){
    return (
        <React.Fragment>
            <div class="main_warp">
      <div class="aboutheader" style={{minHeight: '250px',
        backgroundImage: 'url(assets/images/PostPlaceholder.png)',
        backgroundSize: 'cover'
      }}
        >
         <div class="logohead">
               <a href=""><img src="assets/images/logosm.png" alt=""/></a>
            </div>
        <a class="loactionLefticons" href="index.html">
        <i class="far fa-arrow-left fa-lg" style={{color: "#121416"}}></i>
        </a>
        <div class="loactionbottom">
          <h4>Mumbai, India</h4>
          <h5>4,500,465 POSTS</h5>
        </div>
      </div>
      <div class="container px-0">
        <div class="productdetail">
          <div class="productdetailsmallnav">
            <a href="Info.html">Info</a>
            <a href="Trending.html">Trending Posts</a>
            <a href="Allposts.html">All Posts</a>
            <a href="Faq.html">FAQs</a>
            <a class="productdetailsmallnavactive" href="Authorities.html">Local Authorities</a>
          </div>
          <div
            class="productdetailsmallview"
            id="#Authorities"
            >
            <div class="card mb-0">
              <div class="card-body px-2">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <a href="Listdetail.html">
                      <div class="d-flex flex-row bd-highlight align-items-center">
                        <div class="p-1 bd-highlight mr-1">
                          <div class="Hagcricle">
                            <i class="far fa-hashtag"></i>
                          </div>
                        </div>
                        <div class="p-1 bd-highlight flex-grow-1 viewText">
                          <h6>Mumbai_Police</h6>
                        </div>
                        <div class="p-1 bd-highlight">
                          <div class="Hagcriclesmall">
                            <img
                              width="16"
                              height="16"
                              src="assets/images/link.svg"
                              />
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li class="list-group-item">
                    <a href="Listdetail.html">
                      <div class="d-flex flex-row bd-highlight align-items-center">
                        <div class="p-1 bd-highlight mr-1">
                          <div class="Hagcricle">
                            <i class="far fa-hashtag"></i>
                          </div>
                        </div>
                        <div class="p-1 bd-highlight flex-grow-1 viewText">
                          <h6>BMCC</h6>
                        </div>
                        <div class="p-1 bd-highlight">
                          <div class="Hagcriclesmall">
                            <img
                              width="16"
                              height="16"
                              src="assets/images/link.svg"
                              />
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li class="list-group-item">
                    <a href="Listdetail.html">
                      <div class="d-flex flex-row bd-highlight align-items-center">
                        <div class="p-1 bd-highlight mr-1">
                          <div class="Hagcricle">
                            <i class="far fa-hashtag"></i>
                          </div>
                        </div>
                        <div class="p-1 bd-highlight flex-grow-1 viewText">
                          <h6>Mumbai_Don</h6>
                        </div>
                        <div class="p-1 bd-highlight">
                          <div class="Hagcriclesmall">
                            <img
                              width="16"
                              height="16"
                              src="assets/images/link.svg"
                              />
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li class="list-group-item">
                    <a href="Listdetail.html">
                      <div class="d-flex flex-row bd-highlight align-items-center">
                        <div class="p-1 bd-highlight mr-1">
                          <div class="Hagcricle">
                            <i class="far fa-hashtag"></i>
                          </div>
                        </div>
                        <div class="p-1 bd-highlight flex-grow-1 viewText">
                          <h6>Mumbai_MLA</h6>
                        </div>
                        <div class="p-1 bd-highlight">
                          <div class="Hagcriclesmall">
                            <img
                              width="16"
                              height="16"
                              src="assets/images/link.svg"
                              />
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li class="list-group-item">
                    <a href="Listdetail.html">
                      <div class="d-flex flex-row bd-highlight align-items-center">
                        <div class="p-1 bd-highlight mr-1">
                          <div class="Hagcricle">
                            <i class="far fa-hashtag"></i>
                          </div>
                        </div>
                        <div class="p-1 bd-highlight flex-grow-1 viewText">
                          <h6>Mumbai_Nagar_Sevak</h6>
                        </div>
                        <div class="p-1 bd-highlight">
                          <div class="Hagcriclesmall">
                            <img
                              width="16"
                              height="16"
                              src="assets/images/link.svg"
                              />
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li class="list-group-item">
                    <a href="Listdetail.html">
                      <div class="d-flex flex-row bd-highlight align-items-center">
                        <div class="p-1 bd-highlight mr-1">
                          <div class="Hagcricle">
                            <i class="far fa-hashtag"></i>
                          </div>
                        </div>
                        <div class="p-1 bd-highlight flex-grow-1 viewText">
                          <h6>CST</h6>
                        </div>
                        <div class="p-1 bd-highlight">
                          <div class="Hagcriclesmall">
                            <img
                              width="16"
                              height="16"
                              src="assets/images/link.svg"
                              />
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li class="list-group-item">
                    <a href="Listdetail.html">
                      <div class="d-flex flex-row bd-highlight align-items-center">
                        <div class="p-1 bd-highlight mr-1">
                          <div class="Hagcricle">
                            <i class="far fa-hashtag"></i>
                          </div>
                        </div>
                        <div class="p-1 bd-highlight flex-grow-1 viewText">
                          <h6>Mumbai_Indians</h6>
                        </div>
                        <div class="p-1 bd-highlight">
                          <div class="Hagcriclesmall">
                            <img
                              width="16"
                              height="16"
                              src="assets/images/link.svg"
                              />
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li class="list-group-item">
                    <a href="Listdetail.html">
                      <div class="d-flex flex-row bd-highlight align-items-center">
                        <div class="p-1 bd-highlight mr-1">
                          <div class="Hagcricle">
                            <i class="far fa-hashtag"></i>
                          </div>
                        </div>
                        <div class="p-1 bd-highlight flex-grow-1 viewText">
                          <h6>Bollywood</h6>
                        </div>
                        <div class="p-1 bd-highlight">
                          <div class="Hagcriclesmall">
                            <img
                              width="16"
                              height="16"
                              src="assets/images/link.svg"
                              />
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="card-footer text-muted">
                  <button class="btn showmore-btn w-100" type="button">
                  Show More..
                  </button>
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </div>
        </React.Fragment>
    )
}