import { createSlice } from "@reduxjs/toolkit";

const registrationSlice = createSlice(
    {
        name: "registrationSlice",
        initialState: {
            currentCity: "",
            pincode: "",
            otherCities: ""
        },
        reducers: {
            setCurrentCity: (state, action) => {
                state.currentCity = action.payload;
            },
            setPincode: (state, action) => {
                state.pincode = action.payload;
            },
            setOtherCities: (state, action) => {
                state.otherCities = action.payload;
            }
        }
    }
)

export const {
    setCurrentCity,
    setPincode,
    setOtherCities
} = registrationSlice.actions;

export default registrationSlice.reducer;